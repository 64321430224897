import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, FormControl, MenuItem, Modal, Select, Stack, TextField, Tooltip, Typography, LinearProgress, InputAdornment} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useNavigate, useLocation, useParams, Link} from "react-router-dom"
import { toNumber, round, has, isNull, isNil, isEmpty, isEqual } from 'lodash'
import { useDebounce } from 'use-debounce';

import {useStateContext} from '../../../../context/ContextProvider'
import axios from '../../../../lib/axios'
import { createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleZero, isValid } from '../../../../helpers/helper'
import Materials from '../../../../components/inventory/Items'
import AddButton from '../../../../components/AddButton'
import Loading from '../../../../components/Loading'
import CommentHistoryButton from '../../../../components/CommentHistoryButton'
import CommentHistoryModal from '../../../../components/CommentHistoryModal'
import { useAuth } from '../../../../hooks/auth';

const ProcessProductionOrder = () => {
    const { user } = useAuth({ middleware: 'guest' });
    const { t } = useTranslation()
    const { config, currencySymbol, pusher } = useStateContext()
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    /* state */
    const [random, setRandom] = useState(Math.random())
    const [materials, setMaterials] = useState([])
    const [commentHistory, setCommentHistory] = useState([])
    const [totalCost, setTotalCost] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [statusId, setStatusId] = useState('')
    const [statusName, setStatusName] = useState('')

    const [openCommentHistory, setOpenCommentHistory] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    /* form */
    const [productionOrderNo, setProductionOrderNo] = useState('')
    const [productionOrderDescription, setProductionOrderDescription] = useState('')
    const [itemId, setItemId] = useState('')
    const [itemNo, setItemNo] = useState('')
    const [itemDescription, setItemDescription] = useState('')
    const [itemUnitOfMeasureCode, setItemUnitOfMeasureCode] = useState('')
    const [itemGradeCodeCode, setItemGradeCodeCode] = useState('')
    const [startDate, setStartDate] = useState(dayjs().endOf('day'))
    const [startTime, setStartTime] = useState(dayjs().endOf('minute'))
    const [dueDate, setDueDate] = useState(dayjs().endOf('day'))
    const [comments, setComments] = useState('')
    const [qtyToProduce, setQtyToProduce] = useState(0)
    const [qtyProduced, setQtyProduced] = useState(0)

    const [progressPct, setProgressPct] = useState(0)
    const [progressQty, setProgressQty] = useState(0)
    const [isEditingProgress, setIsEditingProgress] = useState(false)

    /* PUSHER */
    useEffect(() => {
        const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)

        channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                if(Number(data.production_supervisor) != Number(user?.id)) {
                    Swal.fire({
                        icon: "info",
                        customClass: 'info',
                        showCloseButton: true,
                        iconColor: '#a8d5f1',
                        text: t('production_order_reassigned_text')
                    }).then((result) => {
                        navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/production-order', {
                            replace: true
                        })
                    })
                } else {
                    getProductionOrder({
                        id: data.id,
                        dueToPusherEvent: true
                    })
                }
            }
        })
        channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/production-order', {
                    replace: true
                })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
        })
    }, [])

    useEffect(() => {
        if(isValid(itemId)) {
            const channelItemUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-updated`)

            channelItemUpdate.bind(`${localStorage.getItem('client_id')}-item-updated-event`, data => {
                if(Number(data.id) != Number(itemId)) {
                    return
                }

                getProductionOrder({
                    dueToPusherEvent: true
                })
            })
        }

        return (() => {
            if(isValid(itemId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
            }
        })
    }, [itemId])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* UseEffect */

    useEffect(() => {
        getProductionOrder({
            id: params.id
        })
    }, [params.id])

    useEffect(() => {
        calculateTotalCost()
        calculateTotalQuantity()
    }, [materials])

    /* Handlers */

    const calculateTotalCost = () => {
        let tcost = 0
        materials.forEach(material => {
            tcost += (toNumber(material.qty_per_assembly) * toNumber(material.unit_price)) * toNumber(qtyToProduce)
        })
        setTotalCost(round(tcost, 2))
    }

    const calculateTotalQuantity = () => {
        let tqty = 0
        materials.forEach(material => {
            tqty += toNumber(material.qty_per_assembly) * toNumber(qtyToProduce)
        })
        setTotalQty(tqty)
    }

    /* API Requests */
    const getProductionOrder = async ({id = null, dueToPusherEvent = false} = {}) => {
        setIsLoading(true)
        setIsEditingProgress(false)

        await axios
            .get(`/api/production-orders/${isNull(id) ? params.id : id}?with=productionSupervisor,productionOrderItems.item,status`, config)
            .then(res => {
                const data = res.data

                setProductionOrderNo(data.no)
                setProductionOrderDescription(data.description)
                setStartDate(dayjs(data?.start_date))
                setStartTime(dayjs(data?.start_time))
                setDueDate(dayjs(data?.due_date))
                setStatusId(data?.status?.id)
                setStatusName(data?.status?.name)

                setCommentHistory(data.comment_history)

                setItemId(data?.item?.id)
                setItemNo(data?.item?.item_no)
                setItemDescription(data?.item?.description)
                setItemUnitOfMeasureCode(data?.item?.unit_of_measure?.code)
                setItemGradeCodeCode(data?.item?.grade_code?.code)
                setQtyToProduce(data?.qty_to_produce)
                setQtyProduced(data?.qty_produced)
                setProgressQty(data?.qty_produced)
                setProgressPct(round((Number(data?.qty_produced) / Number(data?.qty_to_produce)) * 100, 2))
                setMaterials(data?.production_order_items.map((item) => ({
                    id: item.id,
                    item_id: item.id,
                    sub_assembly_id: item.item?.id,
                    sub_assembly_no: item.item?.item_no,
                    sub_assembly_unit_of_measure_code: item.item?.unit_of_measure_code,
                    description: item.item?.description,
                    unit_price: item.unit_price,
                    qty: item.qty,
                    qty_per_assembly: item.qty_per_assembly,
                    scrap: item.scrap
                })))

                if(!dueToPusherEvent) {
                    setComments('')
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const saveProductionOrder = async () => {
        setIsLoading(true)
        const formData = {}

        formData['qty_produced'] = progressQty
        formData['comments'] = comments

        await axios
            .put(`/api/production-orders/${params.id}/save`, formData, config).then(( data ) => {
                Swal.fire({
                    icon: "success",
                    text: data.data.success.message,
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E'
                })
                setComments('')
                setIsEditingProgress(false)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const saveProductionOrderProgress = async () => {
        setIsLoading(true)
        const formData = {}

        formData['qty_produced'] = progressQty

        await axios
            .put(`/api/production-orders/${params.id}/save`, formData, config).then(( data ) => {
                Swal.fire({
                    icon: "success",
                    text: data.data.success.message,
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E'
                })
                setIsEditingProgress(false)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const completeProductionOrder = async () => {
        setIsLoading(true)
        const formData = {}

        formData['qty_produced'] = progressQty
        formData['comments'] = comments

        await axios
            .put(`/api/production-orders/${params.id}/complete`, formData, config).then(( data ) => {
                Swal.fire({
                    icon: "success",
                    text: data.data.success.message,
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E'
                })
                navigate('/production-order', {
                    replace: true
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleDoNotSaveProgress = () => {
        setProgressQty(qtyProduced)
        setIsEditingProgress(false)
    }

    const handleClose = () => {
        if(
            !isEqual(qtyProduced, progressQty)
            || !isEmpty(comments)
        ) {
            Swal.fire({
                text: t('unsaved_changes_exit_warning'),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showDenyButton: true,
                showCloseButton: false,
                confirmButtonText: t('yes'),
                denyButtonText: t('negatory'),
                allowOutsideClick: false
            }).then((result) => {
                if(result.isConfirmed) {
                    navigate('/production-order')
                }
            })
        } else {
            navigate('/production-order')
        }
    }

    const materialsColumns = [
        {
            field: 'sub_assembly_no',
            headerName: t('item_number_abbr'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialNo params={params} t={t} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialDescription params={params} t={t} />
        },
        {
            field: 'qty_per_assembly',
            headerName: t('qty_per_assembly'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQtyPerAssembly params={params} t={t} />
        },
        {
            field: 'qty',
            headerName: t('totalQty'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQty params={params} t={t} qtyToProduce={qtyToProduce} />,
            valueGetter: (params) => params.row.qty_per_assembly * qtyToProduce,
            sortComparator: (v1, v2) => toNumber(v1) - toNumber(v2)
        },
        {
            field: 'sub_assembly_unit_of_measure_code',
            headerName: t('unit_of_measure'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitOfMeasure params={params} t={t} />
        },
        {
            field: 'scrap',
            headerName: `${t('scrap')} (%)`,
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialScrap params={params} t={t} />
        }/* ,
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitPrice params={params} t={t} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_price',
            headerName: t('total_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialTotalPrice params={params} t={t} currencySymbol={currencySymbol} calculateTotalCost={calculateTotalCost} qtyToProduce={qtyToProduce} />,
            valueGetter: (params) => (params.row.qty_per_assembly * params.row.unit_price) * qtyToProduce,
            sortComparator: (v1, v2) => toNumber(v1) - toNumber(v2)
        } */
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box
                style={{
                    backgroundColor: "#F5F5FD",
                    minHeight: '100vh'
                }}
            >
                {/* HEADER */}
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex flex-wrap gap-3 items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#1ab800] text-[35px] mr-4'>•</span> {productionOrderNo} <span className='mx-3 text-[#D4D4D4] font-light'>|</span> <span className='font-normal'>{statusName}</span> </p>

                        {isEditingProgress ? (
                            <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                justifyContent='end'
                                height='41px'
                                flexGrow='1'
                            >
                                <TextField
                                    sx={{
                                        width: '280px',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    type="text"
                                    name="qty_produced"
                                    label={t('quantity_produced')}
                                    value={progressQty}
                                    onChange={(e) => handleInteger({
                                        event: e,
                                        setter: setProgressQty,
                                        upperLimit: qtyToProduce,
                                        name: t('quantity_produced')
                                    }) }
                                    onBlur={e => handleZero({
                                        event: e,
                                        setter: setProgressQty
                                    })}
                                    onFocus={e => deleteZero({
                                        event: e,
                                        setter: setProgressQty,
                                        value: progressQty
                                    })}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">/ {qtyToProduce}</InputAdornment>
                                    }}
                                />

                                <button
                                    type="button"
                                    onClick={handleDoNotSaveProgress}
                                    className={`h-[41px] text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-red-600 hover:bg-red-800 disabled:bg-red-400`}
                                    disabled={statusId !== 2}
                                >
                                    <i className="fa-solid fa-rotate-left"></i>
                                </button>

                                <button
                                    type="button"
                                    onClick={saveProductionOrderProgress}
                                    className={`w-[117px] h-[41px] text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#6C6C6C] hover:bg-[#565656] disabled:bg-[#989898]`}
                                    disabled={statusId !== 2}
                                >
                                    {t('save')}
                                </button>
                            </Stack>
                        ) : (
                            <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                justifyContent='end'
                                height='41px'
                                flexGrow='1'
                            >
                                <LinearProgress
                                    variant='determinate'
                                    value={progressPct > 100 ? 100 : progressPct}
                                    sx={{
                                        width: '205px',
                                        height: '18px',
                                        borderRadius: '9px',
                                        backgroundColor: "#EDEDED",
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: "#71DD37"
                                        }
                                    }}
                                />

                                <Typography fontSize='0.875rem'>
                                    {progressPct}%
                                </Typography>

                                <Tooltip title={statusId !== 2 ? '' : t('record_quantity_produced')}>
                                    <span>
                                        <button
                                            type="button"
                                            onClick={() => setIsEditingProgress(true)}
                                            disabled={statusId !== 2}
                                            className={`${statusId !== 2 ? 'hidden' : 'block'}`}
                                        >
                                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                                <i className="fa-solid fa-pencil"></i>
                                            </span>
                                        </button>
                                    </span>
                                </Tooltip>
                            </Stack>
                        )}

                        <div className="font-[600] text-[#333333]">
                            <button onClick={handleClose}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                    <i className="fa-solid fa-xmark"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* BODY */}
                <div className='p-5 pb-12'>
                    <div className='flex flex-col lg:flex-row gap-5'>
                        <div className='flex flex-col w-full lg:w-1/2 gap-5'>
                            {/* GENERAL INFORMATION section */}
                            <div
                                className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                style={{
                                    boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                }}
                            >
                                <div className='pb-6 pt-1 flex gap-3 items-center'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>
                                        {t('general_information')}
                                    </p>
                                </div>

                                <Stack direction='column' width='100%'>
                                    {/* Production Order No. */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('production_order_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                name="production_order_no"
                                                value={productionOrderNo}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {/* Production Order Description */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('production_order_description')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                name="production_order_description"
                                                value={productionOrderDescription}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {/* Item To Produce */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('item_to_produce')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                name="item_to_produce_no"
                                                value={`${itemNo} - ${itemDescription}`}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className='w-full pb-4 lg:flex lg:gap-3'>
                                        {/* Quantity To Produce */}
                                        <div className='w-full pb-4 lg:w-1/2 lg:pb-0'>
                                            <p className="text-[14px] text-[#718096]">{t('quantity_to_produce')}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-root': {
                                                            borderTopRightRadius: '0px',
                                                            borderBottomRightRadius: '0px',
                                                        },
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    type="text"
                                                    name="quantity_to_produce"
                                                    value={qtyToProduce}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        {/* Grade Cocde */}
                                        <div className='w-full lg:w-1/2'>
                                            <p className="text-[14px] text-[#718096]">{t('grade_code')}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-root': {
                                                            borderTopLeftRadius: '0px',
                                                            borderBottomLeftRadius: '0px'
                                                        },
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    type="text"
                                                    name="unit_of_measure"
                                                    value={itemGradeCodeCode ?? ''}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                            </div>

                            {/* COMPLETION section */}
                            <div
                                className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                style={{
                                    boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                }}
                            >
                                <div className='pb-6 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>
                                        {t('completion')}
                                    </p>
                                </div>

                                <Stack direction='column' width='100%'>
                                    <div className='lg:flex gap-3 w-full'>
                                        {/* Start Date */}
                                        <div className='w-full lg:w-1/2 pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('start_date')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow:
                                                            'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        format="YYYY-MM-DD"
                                                        value={startDate}
                                                        disabled
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        {/* Start Time */}
                                        <div className='w-full lg:w-1/2 pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('start_time')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                            value={startTime}
                                                            disabled
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Due Date */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('due_date')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    format="YYYY-MM-DD"
                                                    value={dueDate}
                                                    disabled
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-1/2 gap-5'>
                            {/* MATERIAL LINES section */}
                            <div
                                className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                style={{
                                    boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                }}
                            >
                                <div className='pb-6 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>
                                        {t('material_list')}
                                    </p>
                                </div>

                                <div className='border-b border-l border-r rounded'>
                                    <Materials items={materials} columns={materialsColumns} random={random} />
                                </div>

                                <div className='flex justify-end w-full p-5'>
                                    <div className='flex flex-col gap-y-4 w-full lg:w-1/2 2xl:w-1/3'>
                                        {/* <div className='flex justify-between items-center'>
                                            <p className="font-[400] text-[#88909C] text-[14px]">{t('total_cost')}</p>
                                            <p className='font-[500] text-[16px]'>{currencySymbol}{totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div> */}
                                        <div className='flex justify-between items-center'>
                                            <p className="font-[400] text-[#88909C] text-[14px]">{t('totalQty')}</p>
                                            <p className='font-[500] text-[16px]'>{totalQty}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* COMMENTS section */}
                            <div
                                className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                style={{
                                    boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                }}
                            >
                                <div className='pb-3 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>
                                        {t('comments')}
                                    </p>
                                </div>

                                <div className='w-full pb-4'>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                        <CommentHistoryButton
                                            badgeContent={commentHistory?.length ?? ''}
                                            backgroundColor='#1ab800'
                                            onClick={() => setOpenCommentHistory(true)}
                                        />
                                    </div>

                                    <TextField
                                        disabled={statusId !== 2}
                                        multiline
                                        rows={3}
                                        value={comments}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%',
                                            background: '#FCFCFC',
                                            '.MuiOutlinedInput-input': {
                                                padding: '9px !important'
                                            },
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #EEEFF2 !important'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SAVE & COMPLETE section */}
                    <div className='w-full h-fit mt-5'>
                        <div className='w-full flex justify-end gap-3'>
                            <Tooltip disableInteractive title={statusId !== 2 ? t('You must first start the order to begin making changes.') : ''} placement='left'>
                                <span>
                                    <button
                                        type="button"
                                        onClick={saveProductionOrder}
                                        className='w-[117px] h-[41px] text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#6C6C6C] hover:bg-[#565656] disabled:bg-[#989898]'
                                        disabled={statusId !== 2}
                                    >
                                        {t('save')}
                                    </button>
                                </span>
                            </Tooltip>

                            <Tooltip disableInteractive title={statusId !== 2 ? t('You must first start the order to begin making changes.') : ''}>
                                <span>
                                    <button
                                        type="button"
                                        onClick={completeProductionOrder}
                                        className='h-[41px] text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#00C448] hover:bg-[#019738] disabled:bg-[#B8B7BC]'
                                        disabled={statusId !== 2}
                                    >
                                        <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('complete')}
                                    </button>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Box>
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default ProcessProductionOrder

const MaterialNo = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            disabled
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
        />
    )
}

const MaterialDescription = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialQtyPerAssembly = ({ params, t }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            disabled
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
        />
    )
}

const MaterialQty = ({ params, t, qtyToProduce }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.row.qty_per_assembly * qtyToProduce)
    }, [params.row.qty_per_assembly, qtyToProduce])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            disabled
        />
    )
}

const MaterialUnitOfMeasure = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialScrap = ({ params, t, }) => {
    const [scrap, setScrap] = useState(params.value)

    return (
        <TextField
            disabled
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={scrap}
        />
    )
}

const MaterialUnitPrice = ({ params, t, currencySymbol }) => {
    const [unitPrice, setUnitPrice] = useState(params.value)

    useEffect(() => {
        setUnitPrice(params.value)
    }, [params.value])

    return (
        <div className='flex items-center'>
            <span style={{lineHeight: '32px', padding: '4px 0 5px', color: 'rgba(0, 0, 0, 0.38)'}}>{currencySymbol}</span>
            <TextField
                disabled
                type="text"
                variant='standard'
                className='w-full mb-5 px-0 pt-0'
                value={unitPrice}
            />
        </div>
    )
}

const MaterialTotalPrice = ({ params, t, currencySymbol, calculateTotalCost, qtyToProduce }) => {
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        setTotalPrice(round((params.row.qty_per_assembly * params.row.unit_price) * qtyToProduce, 2))
        calculateTotalCost()
    }, [params.row.qty_per_assembly, params.row.unit_price, qtyToProduce])

    return (
        <>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}
