import React, { useEffect, useState, useLayoutEffect } from 'react'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { Box, Switch, Tab, TextField } from '@mui/material'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { isNull, debounce } from 'lodash'

import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import Loading from '../../components/Loading'
import { useAuth } from '../../hooks/auth'
import PurchaseAddresses from '../../components/customers_vendors/PurchaseAddresses'
import PurchaseContracts from '../../components/customers_vendors/PurchaseContracts'
import { getWindowSize } from '../../helpers/helper'
import VendorUsers from '../../components/customer_portal/VendorUsers'

const UpdateVendor = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { config, getCompanies, companies, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState(() => {
        if (
            searchParams.has('tab')
            && ['general', 'communication', 'receiving', 'purchase-locations', 'purchase-contracts', 'vendor-users'].includes(searchParams.get('tab'))
        ) {
            return searchParams.get('tab')
        } else {
            return 'general'
        }
    })
    const [sites, setSites] = useState([])

    /* Necessary to make tablist scrollable */
    const [windowSize, setWindowSize] = useState(getWindowSize())

    /* FORM FIELDS */
    /* general */
    const [no, setNo] = useState("")
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [company, setCompany] = useState("")
    const [blocked, setBlocked] = useState(false)

    /* communication */
    const [phone, setPhone] = useState("")
    const [fax, setFax] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [generalCompanyEmail, setGeneralCompanyEmail] = useState('')
    const [salesContactName, setSalesContactName] = useState('')
    const [accountingContactName, setAccountingContactName]= useState('')
    const [accountingContactPhoneNo, setAccountingContactPhoneNo] = useState('')
    const [accountingEmail, setAccountingEmail] = useState('')
    const [orderDeskContactName, setOrderDeskContactName] = useState('')
    const [orderDeskEmail, setOrderDeskEmail] = useState('')

    /* shipping */
    const [site, setSite] = useState("")
    const [code, setCode] = useState("")

    useEffect(() => {
        if (localStorage.getItem('showImpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('impersonation')}),
                text: t('you_are_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showImpersonationMessage')
            })
        } else if (localStorage.getItem('showDeimpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('deimpersonation')}),
                text: t('you_are_no_longer_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showDeimpersonationMessage')
                localStorage.removeItem('impersonatee_name')
            })
        }

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate('/vendors')
            }
        })


        channelupdate.bind(`${localStorage.getItem('client_id')}-vendor-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getVendor()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-updated`)
        })
    }, [])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate('/vendors')
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* RESIZE EVENT */
    useLayoutEffect(() => {
        function handleStopResizing(e) {
            setWindowSize(getWindowSize())
        }

        const debouncedUpdateSize = debounce(handleStopResizing, 200)

        window.addEventListener('resize', debouncedUpdateSize)

        return (() => {
            window.removeEventListener('resize', debouncedUpdateSize)
        })
    }, [])

    const getVendor = async (id = null) => {
        setIsLoading(true)
        await axios.get(`/api/vendors/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const data = res.data
                setNo(data.vendor_no)
                setName(data.name)
                setAddress(data.address)
                setAddress2(data?.address2 || "")
                setCity(data.city)
                setState(data.state)
                setZip(data.zip)
                setCompany(data.company_id)
                setBlocked(data.blocked ? true : false)
                setPhone(data?.phone || "")
                setFax(data?.fax || "")
                setEmail(data?.email || "")
                setWebsite(data?.website || "")
                setSite(data?.location_id || "")
                setCode(data?.shipping_code || "")
                setGeneralCompanyEmail(data.general_company_email)
                setSalesContactName(data.sales_contact_name)
                setAccountingContactName(data.accounting_contact_name)
                setAccountingContactPhoneNo(data.accounting_contact_phone_no)
                setAccountingEmail(data.accounting_email)
                setOrderDeskContactName(data.order_desk_contact_name)
                setOrderDeskEmail(data.order_desk_email)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    useEffect(() => {
        getVendor()
        isOffice()
    }, [])

    useEffect(() => {
        if (company) getSites(company)
    }, [company])

    useEffect(() => {
        setSearchParams({ tab: value })
    }, [value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleBlocked = (event) => {
        setBlocked(event.target.checked);
    }

    const isOffice = () => {
        if (user?.role === "office_manager") {
            getCompanies()
            setCompany(user?.company_id)
        }
    }


    const handleChangeCompany = (id) => {
        setCompany(id)
        getSites(id)
        setSite(' ')
    }


    const getSites = (id) => {
        setIsLoading(true)
        axios.get(`/api/sites?company_id=${id}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
                setIsLoading(false)
            })
    }

    const updateVendor = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        /* formData['vendor_no'] = no */
        formData['name'] = name
        formData['address2'] = address2
        formData['address'] = address
        formData['company_id'] = company
        formData['location_id'] = site
        formData['fax'] = fax
        formData['phone'] = phone
        formData['website'] = website
        formData['email'] = email
        formData['blocked'] = blocked
        formData['shipping_code'] = code
        formData['city'] = city
        formData['state'] = state
        formData['zip'] = zip

        formData['general_company_email'] = generalCompanyEmail
        formData['sales_contact_name'] = salesContactName
        formData['accounting_contact_name'] = accountingContactName
        formData['accounting_contact_phone_no'] = accountingContactPhoneNo
        formData['accounting_email'] = accountingEmail
        formData['order_desk_contact_name'] = orderDeskContactName
        formData['order_desk_email'] = orderDeskEmail

        await axios.put(`/api/vendors/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setIsLoading(false)

        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    const handleClearSiteClick = () => {
        setSite('')
    }
    const handleClearCodeClick = () => {
        setCode('')
    }
    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>

                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#b7472a] text-[35px] mr-4'>•</span> {t('update_vendor')} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to={`/vendors`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList sx={{ ".Mui-selected": { color: '#b7472a !important'} }} TabIndicatorProps={{ style: { background: "#b7472a" } }} onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                                <Tab sx={{ textTransform: 'none' }} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="communication" label={t('communication')} icon={<i className="fa-solid fa-phone"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="receiving" label={t('receiving')} icon={<i className="fa-solid fa-truck"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="purchase-locations" label={t('purchase_addresses')} icon={<i className="fa-solid fa-address-book"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="purchase-contracts" label={t('purchase_contracts')} icon={<i className="fa-solid fa-money-check-dollar"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="vendor-users" label={t('vendor_users')} icon={<i className="fa-solid fa-users-rays fa-lg"></i>} iconPosition='start' />
                            </TabList>
                        </Box>
                        <TabPanel value="general">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>
                                <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('vendor_type')}</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={code}
                                                    onChange={(e) => { setCode(e.target.value) }}
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        },
                                                        ".MuiSelect-iconOutlined": {
                                                            display: code ? 'none !important' : ''
                                                        }
                                                    }}
                                                    endAdornment={code ? (<IconButton sx={{ visibility: code ? "visible" : "hidden", padding: '0' }} onClick={handleClearCodeClick}><ClearIcon /></IconButton>) : false}
                                                >
                                                    <MenuItem value="VPU">{t('vendor_pickup')}</MenuItem>
                                                    <MenuItem value="3PL">{t('3pl_vendor')}</MenuItem>
                                                    <MenuItem value="BROKER">{t('broker')}</MenuItem>
                                                    <MenuItem value="PALLET-SUPPLIER">{t('pallet_supplier')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="vendor_no" value={no} onChange={(e) => { setNo(e.target.value) }} disabled />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('address')} *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('address2')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={address2} onChange={(e) => { setAddress2(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('city')} *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={city} onChange={(e) => { setCity(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('state')} *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={state} onChange={(e) => { setState(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('zip')} * </p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={zip} onChange={(e) => { setZip(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select value={company} onChange={(e) => { setCompany(e.target.value) }}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}>
                                                    {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('blocked')} </p>
                                        <div className='w-full'>
                                            <Switch checked={blocked} onChange={handleBlocked} inputProps={{ 'aria-label': 'controlled' }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel value="receiving">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('default_site')}</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={site}
                                                    onChange={(e) => { setSite(e.target.value) }}
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        },
                                                        ".MuiSelect-iconOutlined": {
                                                            display: site ? 'none !important' : ''
                                                        }
                                                    }}
                                                    endAdornment={site ? (<IconButton sx={{ visibility: site ? "visible" : "hidden", padding: '0' }} onClick={handleClearSiteClick}><ClearIcon /></IconButton>) : false}
                                                >
                                                    {sites?.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </TabPanel>
                        <TabPanel value="communication">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>
                                    {/* General Company Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('general_company_email')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="general_company_email"
                                                value={generalCompanyEmail}
                                                onChange={(e) => { setGeneralCompanyEmail(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Sales Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_name')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="sales_contact_name"
                                                value={salesContactName}
                                                onChange={(e) => { setSalesContactName(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Sales Contact Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_email')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="sales_contact_email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>

                                    {/* Sales Contact Phone No. */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_phone_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="sales_contact_phone_no" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>

                                    {/* Accounting Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_contact_name')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="accounting_contact_name"
                                                value={accountingContactName}
                                                onChange={(e) => { setAccountingContactName(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Accounting Contact Phone No. */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_contact_phone_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="accounting_contact_name"
                                                value={accountingContactPhoneNo}
                                                onChange={(e) => { setAccountingContactPhoneNo(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Accounting Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_email')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="accounting_email"
                                                value={accountingEmail}
                                                onChange={(e) => { setAccountingEmail(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Order Desk Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('order_desk_contact_name')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="order_desk_contact_name"
                                                value={orderDeskContactName}
                                                onChange={(e) => { setOrderDeskContactName(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Order Desk Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('order_desk_email')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                className='w-full'
                                                name="order_desk_email"
                                                value={orderDeskEmail}
                                                onChange={(e) => { setOrderDeskEmail(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    {/* Fax */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('fax')} </p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={fax} onChange={(e) => { setFax(e.target.value) }} />
                                        </div>
                                    </div>

                                    {/* Website */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('website')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="drop_trailer_no" value={website} onChange={(e) => { setWebsite(e.target.value) }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel value="purchase-locations" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <PurchaseAddresses no={no} id={params.id} setIsLoading={setIsLoading} />
                        </TabPanel>
                        <TabPanel value="purchase-contracts" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <PurchaseContracts vendorId={params.id} setIsLoading={setIsLoading} vendorName={name} />
                        </TabPanel>
                        <TabPanel value="vendor-users" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <VendorUsers
                                vendorNo={no}
                                vendorId={params.id}
                                vendorName={name}
                                vendorCompanyId={company}
                                vendorSiteId={site}
                                setIsLoading={setIsLoading}
                            />
                        </TabPanel>
                    </TabContext>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateVendor} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateVendor
