import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Box, FormControl, MenuItem, Modal, Select, TextField, Tooltip, Stack} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useNavigate, useLocation} from "react-router-dom"
import MenuBookIcon from '@mui/icons-material/MenuBook'

import style from '../../styles/style'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddSalesOrderItem from './AddSalesOrderItem'
import { createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleZero, isValid } from '../../helpers/helper'
import { toNumber, includes } from 'lodash'

const CreateSalesOrderCustomerUser = ({ open, handleOpen, setIsLoading, order }) => {

    const { t } = useTranslation()
    const { company_id, config, setCompany_id, choosesite, setChoosesite, currencySymbol } = useStateContext()
    const navigate = useNavigate()
    const location = useLocation()

    /* state */
    const [shipToLocations, setShipToLocations] = useState([])
    const [salesOrderItems, setSalesOrderItems] = useState(order?.sales_order_items || [])
    const [openSalesOrderItem, setOpenSalesOrderItem] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)

    /* form */
    const [customer] = useState(order?.customer_id)
    const [customerName] = useState(order?.customer_name)
    const [shipToCode, setShipToCode] = useState(order?.ship_to_location_id)
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs(order?.requested_shipment_date).endOf('day'))
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs(order?.requested_shipment_date).endOf('minute'))
    const [shippedDate, setShippedDate] = useState(dayjs().endOf('day'))
    const [customerPoNo, setCustomerPoNo] = useState(order?.customer_po_no)
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [requestCreatorComments] = useState(order?.comments || '')
    const [comments, setComments] = useState('')
    const [deliveryTicketNo, setDeliveryTicketNo] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key == 'Enter' && open) {
                createSalesOrder()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        customer,
        shipToCode,
        dropTrailerNo,
        customerPoNo,
        requestedDeliveryDate,
        requestedDeliveryTime,
        shippedDate,
        choosesite,
        salesOrderItems,
        comments,
        deliveryTicketNo
    ])

    /* UseEffect */
    useEffect(() => {
        getShipToLocations(customer)
    }, [customer])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [salesOrderItems])

    /* Handlers */

    const handleTotalPrice = () => {
        const sum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = salesOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }

    const handleToggleModal = () => {
        handleOpen()
    }


    const handleCustomerPoNo = (e) => {
        const limit = 50;
        setCustomerPoNo(e.target.value.slice(0, limit));
    }

    const handleShipToLocation = (e) => {
        setShipToCode(e.target.value)
        const item = shipToLocations.find(item => item.id === e.target.value)
        setAddress(item.address)
        setZip(item.zip)
        setCity(item.city)
        setState(item.state)
        setDeliveryInstructions(item.delivery_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const salesOrderItemsCopy = [...salesOrderItems]
        const item = salesOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setSalesOrderItems(salesOrderItemsCopy)
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...salesOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(qts)


        setSalesOrderItems(purchaseOrderItemsCopy)
    }

    const handleOpenSalesOrderItem = () => {
        setDisabled(true)
        setOpenSalesOrderItem(!openSalesOrderItem)
    }


    /* API Requests */

    const getShipToLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const shipToLocations = res.data
                setShipToLocations(shipToLocations)
                setShipToCode(order?.ship_to_location_id || '')
                setAddress(shipToLocations.find(({ id }) => Number(id) == Number(order?.ship_to_location_id))?.address || '')
                setZip(shipToLocations.find(({ id }) => Number(id) == Number(order?.ship_to_location_id))?.zip || '')
                setCity(shipToLocations.find(({ id }) => Number(id) == Number(order?.ship_to_location_id))?.city || '')
                setState(shipToLocations.find(({ id }) => Number(id) == Number(order?.ship_to_location_id))?.state || '')
                setDeliveryInstructions(shipToLocations.find(({ id }) => Number(id) == Number(order?.ship_to_location_id))?.delivery_instructions || '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createSalesOrder = async ({shouldProceedAnyway = false} = {}) => {
        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customer
        formData['ship_address_id'] = shipToCode
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = order.site_id ?? choosesite
        formData['sales_order_items'] = salesOrderItems
        formData['so_status_id'] = 1
        formData['comments'] = comments
        formData['request_creator_comments'] = requestCreatorComments
        formData['type'] = 'SHIPMENT'
        formData['proceed_anyway'] = shouldProceedAnyway
        formData['delivery_ticket_no'] = deliveryTicketNo

        formData['request_id'] = order.id

        await axios.put(`/api/shipment-requests/accept-request/${order?.id}`, formData, config).then(( data ) => {
            if(data.status === 202) {
                Swal.fire({
                    title: t('caution'),
                    text: data.data.accepted?.description ?? '',
                    icon: 'warning',
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FEB806',
                    reverseButtons: true,
                    showCancelButton: true,

                    confirmButtonText: t('go'),
                    cancelButtonText: t('cancel')
                }).then((result) => {
                    if(result.isConfirmed) {
                        createSalesOrder({
                            shouldProceedAnyway: true
                        })
                    }
                })
            } else {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.data.success.message
                })

                navigate("/sales-order/" + data.data.success.updated['so_id'], { state: { prevPathname: location.pathname } })

                handleToggleModal()
            }
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipCell params={params} t={t} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.7,
            minWidth: 75,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} t={t} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} />
        },
    ]

    return (
        <>
            <Modal open={open} onClose={handleToggleModal}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_new_so')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                            <div className='pb-11 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                            </div>
                            {/* Customer */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" disabled className='w-full' name="drop_trailer_no" value={customerName} />
                                </div>
                            </div>

                            {/* Ship To Location */}
                            <div className='w-full pb-4'>
                                <Stack
                                    direction='row'
                                    gap={1}
                                    alignItems='baseline'
                                    justifyContent='space-between'
                                >
                                    <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                    {(shipToCode && isValid(deliveryInstructions)) ? (
                                        <Tooltip
                                            title={
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {deliveryInstructions}
                                                </div>
                                            }
                                        >
                                            <Stack
                                                direction='row'
                                                gap={1}
                                                alignItems='center'
                                                className='text-[#718096]'
                                            >
                                                <MenuBookIcon fontSize='18px'/>
                                                <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                            </Stack>
                                        </Tooltip>
                                    ) : null}
                                </Stack>

                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            value={shipToCode} onChange={(e) => { handleShipToLocation(e) }} disabled>
                                            {shipToLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                shipToCode ?
                                    <div className='w-full p-4 address mb-4'>
                                        <div className='w-full'>{address}</div>
                                        {shipToCode ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                    </div>
                                    :
                                    null
                            }

                            {/* Drop Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                                </div>
                            </div>
                            {/* Pickup Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                                </div>
                            </div>
                            {/* Customer PO No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer_po_no')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="customer_po_no" value={customerPoNo} onChange={(e) => { handleCustomerPoNo(e) }} />
                                    <p className='text-xs italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                                </div>
                            </div>

                            {/* Delivery Ticket No. */}
                            {
                                includes(['oliverswoodworking'], localStorage.getItem('client_id')) ? (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('delivery_ticket_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="delivery_ticket_no" value={deliveryTicketNo} onChange={(e) => { setDeliveryTicketNo(e.target.value) }} />
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div className='lg:flex gap-3 w-full'>
                                {/* Requested Delivery Date */}
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_delivery_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                format="YYYY-MM-DD" value={requestedDeliveryDate} onChange={(newValue) => setRequestedDeliveryDate(newValue)} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_shipment_time')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                <MobileTimePicker
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    value={requestedDeliveryTime} onChange={(newValue) => setRequestedDeliveryTime(newValue)} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            {/* Shippment Date */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" value={shippedDate} onChange={(newValue) => setShippedDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-3'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                                </div>
                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addsalesOrderitems')} placement='bottom'>
                                        <div>
                                            <AddButton onClick={handleOpenSalesOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='border-b'>
                                    {
                                        salesOrderItems.length > 0 ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setSalesOrderItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                    }
                                    <TableShared columns={columns} items={salesOrderItems} disableColumnMenu={true} />
                                </div>
                                <div className='flex justify-end w-full p-5'>
                                    <div className='w-full lg:w-1/3'>
                                        <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                            <p>{t('totalPrice')}</p>
                                            <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                        <div className='flex justify-between text-[#333333] text-[15px]'>
                                            <p>{t('totalQty')}</p>
                                            <p className='font-[500]'>{totalQty}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 p-5'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    </div>
                                    <div className='w-full p-5 pt-0'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                        <TextField
                                            multiline
                                            rows={3}
                                            value={comments}
                                            onChange={e => setComments(e.target.value)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                        />
                                    </div>

                                    <div className='w-full p-5 pt-0'>
                                        <p className="text-[14px] text-[#718096]">{t('request_creator_comments')}</p>
                                        <TextField
                                            multiline
                                            rows={3}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            disabled
                                            value={requestCreatorComments}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={createSalesOrder} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <AddSalesOrderItem open={openSalesOrderItem} handleOpen={handleOpenSalesOrderItem} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} disabled={disabled} setDisabled={setDisabled} />
        </>
    )
}



export default CreateSalesOrderCustomerUser

const maxQtyDigits = 5

const QtyOrderedCell = ({ params, handler, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_ship) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_ship
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToShipCell = ({ params, t, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        text: t('warningSalesOrder'),
                        icon: 'warning',
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000',
                        reverseButtons: true,
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value,
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-ship"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyShippedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-shipped"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            })}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const UnitPriceCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const Actions = ({ params, t, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
