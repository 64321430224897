import React, { useState, useEffect } from 'react'

import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import DailyBoardTableSales from '../../components/DailyBoardTableSales'
import SalesOrderDailyBoardTable from './SalesOrderDailyBoardTable'
import { useAuth } from '../../hooks/auth'
import { isValid } from '../../helpers/helper'
import Navigation from '../../components/sales_purchase_orders/Navigation'
import { toNumber } from 'lodash'

const SalesOrderDailyBoard = () => {

    const { user } = useAuth({ middleware: 'guest' })

    const { t } = useTranslation()
    const { choosesite, setChoosesite, company_id, config, pusher } = useStateContext()

    const [isLoading, setIsLoading] = useState(false)
    const [random, setRandom] = useState(null)

    const [salesOrderStatuses, setSalesOrderStatuses] = useState([])
    const [salesOrderStatusesFuture, setSalesOrderStatusesFuture] = useState([])
    const [salesOrderDates, setSalesOrderDates] = useState([])
    const [salesOrderDatesFuture, setSalesOrderDatesFuture] = useState([])

    /* orders */
    const [orders, setOrders] = useState([])
    const [ordersFuture, setOrdersFuture] = useState([])

    const [brokerTransactions, setBrokerTransactions] = useState([])
    const [brokerTransactionsFuture, setBrokerTransactionsFuture] = useState([])
    const [brokerTransactionStatuses, setBrokerTransactionStatuses] = useState([])
    const [brokerTransactionStatusesFuture, setBrokerTransactionStatusesFuture] = useState([])
    const [brokerTransactionDates, setBrokerTransactionDates] = useState([])
    const [brokerTransactionDatesFuture, setBrokerTransactionDatesFuture] = useState([])

    /* filters */
    const [searchStatusFuture, setSearchStatusFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_sales_order_status || ''
        } else {
            return ''
        }
    })
    const [searchDateFuture, setSearchDateFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_sales_order_requested_delivery_date || ''
        } else {
            return ''
        }
    })
    const [searchStatus, setSearchStatus] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_status || ''
        } else {
            return ''
        }
    })
    const [searchDate, setSearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_requested_delivery_date || ''
        } else {
            return ''
        }
    })

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        const channelVendorUserUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)

        channelVendorUserUpdated.bind(`${localStorage.getItem('client_id')}-vendoruser-updated-event`, data => {
            if(data.id == user?.id) {
                setRandom(Math.random())
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
        })
    }, [user])

    useEffect(() => {
        if(isValid(choosesite)) {
            const channelSalesOrderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelSalesOrderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelBrokerTransactionUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${choosesite}`)

            channelSalesOrderDeleted.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                if(toNumber(data.vendor_id) == toNumber(user?.service_provider_id)) {
                    setRandom(Math.random())
                }
            })

            channelSalesOrderUpdated.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                if(toNumber(data.vendor_id) == toNumber(user?.service_provider_id)) {
                    setRandom(Math.random())
                }
            })

            channelBrokerTransactionUpdated.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${choosesite}`, data => {
                if(toNumber(data.vendor_id) == toNumber(user?.service_provider_id)) {
                    setRandom(Math.random())
                }
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if (isValid(choosesite)) {
            getBrokerTransactions()
        }
    }, [choosesite, searchDate, searchStatus, random])

    useEffect(() => {
        if (isValid(choosesite)) {
            getBrokerTransactionsFuture()
        }
    }, [choosesite, searchDateFuture, searchStatusFuture, random])

    const getBrokerTransactions = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/list-orders?order_type=broker&site_id=${choosesite}&status_id=${searchStatus}&requested_delivery_date=${searchDate}`, config)
            .then(res => {
                const data = res.data?.data
                getBrokerTransactionFilterOptions(data)
                setBrokerTransactions(
                    data
                        .filter((transaction) => new Date(dayjs(transaction.requested_delivery_date)) <= new Date(dayjs()))
                        .map((transaction) => ({
                            ...transaction.sales_order,
                            broker_transaction_id: transaction.id,
                            customer_name: transaction.customer.name,
                            purchase_order_items: transaction.purchase_order.items
                        }))
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getBrokerTransactionsFuture = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/list-orders?order_type=broker&site_id=${choosesite}&status_id=${searchStatusFuture}&requested_delivery_date=${searchDateFuture}`, config)
            .then(res => {
                const data = res.data?.data
                getBrokerTransactionFutureFilterOptions(data)
                setBrokerTransactionsFuture(
                    data
                        .filter((transaction) => new Date(dayjs(transaction.requested_delivery_date)) > new Date(dayjs()))
                        .map((transaction) => ({
                            ...transaction.sales_order,
                            broker_transaction_id: transaction.id,
                            customer_name: transaction.customer.name,
                            purchase_order_items: transaction.purchase_order.items
                        }))
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getBrokerTransactionFilterOptions = (brokerTransactions) => {
        let pastPresentBrokerTransactions = brokerTransactions.filter((item) => new Date(dayjs(item.requested_delivery_date)) <= new Date(dayjs()))

        let dates = []
        let statuses = []

        pastPresentBrokerTransactions.forEach(transaction => {
            const newDate = transaction.requested_delivery_date;
            const newStatus = {
                id: transaction.bt_status_id,
                name: transaction.bt_status_name,
            }

            const newDateExists = dates.includes(newDate);
            const newStatusExists = statuses.some(function (status) {
                return status.id === newStatus.id
            })

            if (!newDateExists) {
                dates.push(newDate)
            }
            if (!newStatusExists) {
                statuses.push(newStatus);
            }
        })

        dates.sort()
        statuses.sort((a, b) => {
            return a.id - b.id
        })

        setBrokerTransactionDates(dates)
        setBrokerTransactionStatuses(statuses)
    }

    const getBrokerTransactionFutureFilterOptions = (brokerTransactions) => {
        let futureBrokerTransactions = brokerTransactions.filter((item) => new Date(dayjs(item.requested_delivery_date)) > new Date(dayjs()))

        let futureDates = []
        let futureStatuses = []

        futureBrokerTransactions.forEach(transaction => {
            const newFutureDate = transaction.requested_delivery_date;
            const newFutureStatus = {
                id: transaction.bt_status_id,
                name: transaction.bt_status_name,
            }

            const newFutureDateExists = futureDates.includes(newFutureDate);
            const newFutureStatusExists = futureStatuses.some(function (status) {
                return status.id === newFutureStatus.id
            })

            if (!newFutureDateExists) {
                futureDates.push(newFutureDate)
            }
            if (!newFutureStatusExists) {
                futureStatuses.push(newFutureStatus)
            }
        })

        futureDates.sort()
        futureStatuses.sort((a, b) => {
            return a.id - b.id
        })

        setBrokerTransactionDatesFuture(futureDates)
        setBrokerTransactionStatusesFuture(futureStatuses)
    }

    const handleClearStatus = () => {
        setSearchStatusFuture('')

        getBrokerTransactionFutureFilterOptions(brokerTransactionsFuture)
    }

    const handleClearStatus1 = () => {
        setSearchStatus('')

        getBrokerTransactionFilterOptions(brokerTransactions)
    }

    const handleClearDate = () => {
        setSearchDateFuture('')

        getBrokerTransactionFutureFilterOptions(brokerTransactionDatesFuture)
    }

    const handleClearDate1 = () => {
        setSearchDate('')

        getBrokerTransactionFilterOptions(brokerTransactions)
    }

    const createFilterArray = (status, requestedDeliveryDate, futureStatus, futureRequestedDeliveryDate) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].sales_order_status = status
        filters[0].sales_order_requested_delivery_date = requestedDeliveryDate
        filters[0].future_sales_order_status = futureStatus
        filters[0].future_sales_order_requested_delivery_date = futureRequestedDeliveryDate

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchStatus, searchDate, searchStatusFuture, searchDateFuture)
    }, [searchStatus, searchDate, searchStatusFuture, searchDateFuture])

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <Navigation route='sales-orders' user={user} />

                <div className='p-6 flex w-full items-start justify-between gap-5 max-[1300px]:flex-col'>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ transform: 'rotate(45deg)', fontSize: '16px', color: '#336195' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('todays_sales_orders')}</p>
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                        <Select value={searchStatus} onChange={e => setSearchStatus(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatus ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatus ? (<IconButton sx={{ visibility: searchStatus ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                brokerTransactionStatuses.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    brokerTransactionStatuses.map((status, index) => (
                                                        <MenuItem key={`${index}-s-${status.id}`} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select value={searchDate} onChange={e => setSearchDate(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                brokerTransactionDates.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    brokerTransactionDates.map((date, index) => (
                                                        <MenuItem key={`${index}-d-${date}`} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div>
                            <SalesOrderDailyBoardTable items={brokerTransactions} user={user} setIsLoading={setIsLoading} hideCollapsible={false} />
                        </div>
                    </div>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ transform: 'rotate(45deg)', fontSize: '16px', color: '#336195' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('future_sales_orders')}</p>
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            value={searchStatusFuture}
                                            onChange={e => setSearchStatusFuture(e.target.value)}
                                            label="Search role"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatusFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatusFuture ? (<IconButton sx={{ visibility: searchStatusFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                brokerTransactionStatusesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    brokerTransactionStatusesFuture.map((status, index) => (
                                                        <MenuItem key={`${index}-sf-${status.id}`} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                        </Select>

                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select
                                            value={searchDateFuture}
                                            onChange={e => setSearchDateFuture(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDateFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDateFuture ? (<IconButton sx={{ visibility: searchDateFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                brokerTransactionDatesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    brokerTransactionDatesFuture.map((date, index) => (
                                                        <MenuItem key={`${index}-df-${date}`} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div>
                            <SalesOrderDailyBoardTable items={brokerTransactionsFuture} user={user} setIsLoading={setIsLoading} hideCollapsible={false} />
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default SalesOrderDailyBoard

