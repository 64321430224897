import { Modal, Box, IconButton, Typography, Stack, Chip, TextField, Divider } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History'
import ClearIcon from "@mui/icons-material/Clear"
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { lowerCase, isEmpty } from "lodash";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { TEXTFIELD_STYLE } from "../constants/constants";
import axios from "../lib/axios";
import Swal from "sweetalert2";
import { useStateContext } from "../context/ContextProvider";
import { handleAxiosError } from "../helpers/helper";

const BrokerTransactionEmailDraftsModal = ({
    open,
    setOpen,
    setIsLoading,
    isLoadingEmailDrafts,
    setEmailsSent,
    brokerTransactionId,
    drafts = {},
    addresses = {},
    logisticsType = 'delivery'
}) => {
    const { t } = useTranslation()
    const { config } = useStateContext()

    const [subjects, setSubjects] = useState({
        customer: '',
        supplier: '',
        tpl_company: ''
    })

    const debouncedSubjects = useDebouncedCallback((value) => setSubjects(value), 500)

    const [contents, setContents] = useState({
        customer: '',
        supplier: '',
        tpl_company: ''
    })

    const debouncedContents = useDebouncedCallback((value) => setContents(value), 500)

    const [tos, setTos] = useState({})

    useEffect(() => {
        if(Object.keys(drafts).length > 0) {
            setSubjects({
                customer: drafts?.customer?.subject ?? '',
                supplier: drafts?.supplier?.subject ?? '',
                tpl_company: drafts?.tpl_company?.subject ?? ''
            })

            setContents({
                customer: drafts?.customer?.content ?? '',
                supplier: drafts?.supplier?.content ?? '',
                tpl_company: drafts?.tpl_company?.content ?? ''
            })
        }
    }, [drafts])

    useEffect(() => {
        if(Object.keys(addresses).length > 0) {
            setTos(addresses)
        }
    }, [addresses])

    const sendEmails = async (e) => {
        setIsLoading(true)
        e.preventDefault()

        const formData = {}
        formData['subjects'] = subjects
        formData['contents'] = contents

        await axios
            .put(`/api/broker-transactions/${brokerTransactionId}/send-emails?CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`, formData, config)
            .then(( data ) => {
                if(data.status === 202) {
                    Swal.fire({
                        title: t('caution'),
                        text: data.data.accepted?.description ?? '',
                        icon: 'warning',
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806',
                    })
                } else {
                    Swal.fire({
                        icon: "success",
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E',
                        text: data.data.success.message
                    })
                    setEmailsSent(true)
                }

                setOpen(false)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleRestore = () => {
        setSubjects({
            customer: drafts?.customer?.subject ?? '',
            supplier: drafts?.supplier?.subject ?? '',
            tpl_company: drafts?.tpl_company?.subject ?? ''
        })

        setContents({
            customer: drafts?.customer?.content ?? '',
            supplier: drafts?.supplier?.content ?? '',
            tpl_company: drafts?.tpl_company?.content ?? ''
        })
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
                {/* Header */}
                <div style={{ backgroundColor: 'transparent', marginBottom: '7px'}}>
                    <div className='flex items-center justify-between'>
                        <Typography
                            component='h6'
                            sx={{
                                fontWeight: '600',
                                fontSize: '18px',
                                lineHeight: '24px',
                                color: '#333333',
                                textTransform: 'capitalize'
                            }}
                        >
                            {t('email_previews')}
                        </Typography>
                        <IconButton sx={{ color: '#333333' }} onClick={() => setOpen(false)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>

                <Grid container spacing={2}>
                    {/* CUSTOMER email draft */}
                    <Grid xs={12} md={logisticsType === 'tpl' ? 4 : 6}>
                        <Stack direction='column' gap={1}>
                            <Typography>
                                {t('customer')}
                            </Typography>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('to')}</p>
                                <TextField
                                    value={tos.customer}
                                    sx={TEXTFIELD_STYLE}
                                    disabled
                                />
                            </Stack>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('subject')}</p>
                                <TextField
                                    value={subjects.customer}
                                    onChange={(e) => setSubjects({
                                        ...subjects,
                                        customer: e.target.value
                                    })}
                                    sx={TEXTFIELD_STYLE}
                                    disabled={isLoadingEmailDrafts}
                                />
                            </Stack>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('content')}</p>
                                <TextField
                                    multiline
                                    rows={5}
                                    value={contents.customer}
                                    onChange={(e) => setContents({
                                        ...contents,
                                        customer: e.target.value
                                    })}
                                    sx={TEXTFIELD_STYLE}
                                    disabled={isLoadingEmailDrafts}
                                />
                            </Stack>
                        </Stack>
                    </Grid>

                    {/* SUPPLIER email draft */}
                    <Grid xs={12} md={logisticsType === 'tpl' ? 4 : 6}>
                        <Stack direction='column' gap={1}>
                            <Typography>
                                {t('supplier')}
                            </Typography>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('to')}</p>
                                <TextField
                                    value={tos.supplier}
                                    sx={TEXTFIELD_STYLE}
                                    disabled
                                />
                            </Stack>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('subject')}</p>
                                <TextField
                                    value={subjects.supplier}
                                    onChange={(e) => setSubjects({
                                        ...subjects,
                                        supplier: e.target.value
                                    })}
                                    sx={TEXTFIELD_STYLE}
                                    disabled={isLoadingEmailDrafts}
                                />
                            </Stack>

                            <Stack direction='column'>
                                <p className="text-[14px] text-[#718096] capitalize">{t('content')}</p>
                                <TextField
                                    multiline
                                    rows={5}
                                    value={contents.supplier}
                                    onChange={(e) => setContents({
                                        ...contents,
                                        supplier: e.target.value
                                    })}
                                    sx={TEXTFIELD_STYLE}
                                    disabled={isLoadingEmailDrafts}
                                />
                            </Stack>
                        </Stack>
                    </Grid>

                    {/* 3PL COMPANY email draft */}
                    {logisticsType === 'tpl' ? (
                        <Grid xs={12} md={4}>
                            <Stack direction='column' gap={1}>
                                <Typography>
                                    {t('tpl_company')}
                                </Typography>

                                <Stack direction='column'>
                                    <p className="text-[14px] text-[#718096] capitalize">{t('to')}</p>
                                    <TextField
                                        value={tos.tpl_company}
                                        sx={TEXTFIELD_STYLE}
                                        disabled
                                    />
                                </Stack>

                                <Stack direction='column'>
                                    <p className="text-[14px] text-[#718096] capitalize">{t('subject')}</p>
                                    <TextField
                                        value={subjects.tpl_company}
                                        onChange={(e) => setSubjects({
                                            ...subjects,
                                            tpl_company: e.target.value
                                        })}
                                        sx={TEXTFIELD_STYLE}
                                        disabled={isLoadingEmailDrafts}
                                    />
                                </Stack>

                                <Stack direction='column'>
                                    <p className="text-[14px] text-[#718096] capitalize">{t('content')}</p>
                                    <TextField
                                        multiline
                                        rows={5}
                                        value={contents.tpl_company}
                                        onChange={(e) => setContents({
                                            ...contents,
                                            tpl_company: e.target.value
                                        })}
                                        sx={TEXTFIELD_STYLE}
                                        disabled={isLoadingEmailDrafts}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    ) : null}
                </Grid>

                <Stack
                    marginTop='14px'
                    direction='row'
                    gap={1}
                    justifyContent='flex-end'
                >
                    <button
                        disabled={
                            (debouncedSubjects.isPending() || debouncedContents.isPending())
                            || isLoadingEmailDrafts
                        }
                        type="button"
                        onClick={handleRestore}
                        className='text-[#001e67] disabled:text-[#B8B7BC] font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] border-2 border-[#001e67] hover:border-[#001a5a] disabled:border-[#B8B7BC]'
                    >
                        <span className='pr-2'><i className="fa-solid fa-arrow-rotate-left"></i></span> {t('restore')}
                    </button>

                    <button
                        disabled={
                            (debouncedSubjects.isPending() || debouncedContents.isPending())
                            || isLoadingEmailDrafts
                        }
                        type="button"
                        onClick={sendEmails}
                        className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#001e67] hover:bg-[#001a5a] disabled:bg-[#B8B7BC]'
                    >
                        <span className='pr-2'><i className="fa-solid fa-file-import"></i></span> {t('send')}
                    </button>
                </Stack>

            </Box>
        </Modal>
    )
}

export default BrokerTransactionEmailDraftsModal

const style = {
    padding: '21px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "1280px",
    minWidth: "400px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
