import React, { useState, useEffect } from 'react'

import { Autocomplete, TextField, Modal, Box, Button, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, MenuItem, Select, InputLabel, IconButton, ListItemText, ListItemIcon, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import ClearIcon from "@mui/icons-material/Clear";
import { REPORT_CODES } from '../../constants/constants';
import axios from '../../lib/axios';
import { useStateContext } from '../../context/ContextProvider';
import FormHelperText from '@mui/material/FormHelperText'
import { snakeCase, concat } from 'lodash'
import { extractErrorDescription, extractErrorMessage, sortByProperty } from '../../helpers/helper';
import { useAuth } from '../../hooks/auth';
import Swal from 'sweetalert2'

const ReportModal = ({
    open,
    setOpen,
    reportCode,
    companyVendors = [],
    companyCustomers = [],
    companyDrivers = [],
    companyForklifters = [],
    companyUsers = [],
    companyProductionEmployees = [],
    companyProductionTypes = [],
    companySites = [],
    onClose,
    setIsLoading
}) => {

    const { t } = useTranslation()
    const { config, choosesite, allSites } = useStateContext()
    const { user: currentUser } = useAuth({ middleware: 'guest' })

    /* State(s) */
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())

    const [vendor, setVendor] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [vendors, setVendors] = useState([])
    const [site, setSite] = useState('')
    const [siteName, setSiteName] = useState('')
    const [sites, setSites] = useState([])
    const [purchaseAddress, setPurchaseAddress] = useState('')
    const [purchaseAddressName, setPurchaseAddressName] = useState('')
    const [purchaseAddresses, setPurchaseAddresses] = useState([])

    const [customer, setCustomer] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customers, setCustomers] = useState([])
    const [shipToAddress, setShipToAddress] = useState('')
    const [shipToAddressName, setShipToAddressName] = useState('')
    const [driver, setDriver] = useState('')
    const [driverName, setDriverName] = useState('')
    const [drivers, setDrivers] = useState([])
    const [forklifter, setForklifter] = useState('')
    const [forklifterName, setForklifterName] = useState('')
    const [forklifters, setForklifters] = useState([])
    const [user, setUser] = useState('')
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])
    const [productionEmployee, setProductionEmployee] = useState('')
    const [productionEmployeeName, setProductionEmployeeName] = useState('')
    const [productionEmployees, setProductionEmployees] = useState([])
    const [shipToAddresses, setShipToAddresses] = useState([])
    const [productionTypes, setProductionTypes] = useState([])
    const [items, setItems] = useState([])

    const [selectedPurchaseAddresses, setSelectedPurchaseAddresses] = useState([])
    const everyPurchaseAddressSelected = purchaseAddresses.length > 0 && selectedPurchaseAddresses.length === purchaseAddresses.length
    const [selectedShipToAddresses, setSelectedShipToAddresses] = useState([])
    const everyShipToAddressSelected = shipToAddresses.length > 0 && selectedShipToAddresses.length === shipToAddresses.length
    const [selectedProductionTypes, setSelectedProductionTypes] = useState([])
    const everyProductionTypeSelected = productionTypes.length > 0 && selectedProductionTypes.length === productionTypes.length
    const [selectedItems, setSelectedItems] = useState([])
    const everyItemSelected = items.length > 0 && selectedItems.length === items.length

    const [errorVendor, setErrorVendor] = useState(false)
    const [errorSite, setErrorSite] = useState(false)
    const [errorPurchaseAddress, setErrorPurchaseAddress] = useState(false)
    const [errorCustomer, setErrorCustomer] = useState(false)
    const [errorShipToAddress, setErrorShipToAddress] = useState(false)
    const [errorDriver, setErrorDriver] = useState(false)
    const [errorForklifter, setErrorForklifter] = useState(false)
    const [errorUser, setErrorUser] = useState(false)
    const [errorProductionEmployee, setErrorProductionEmployee] = useState(false)
    const [errorSelectedPurchaseAddresses, setErrorSelectedPurchaseAddresses] = useState(false)
    const [errorSelectedShipToAddresses, setErrorSelectedShipToAddresses] = useState(false)
    const [errorSelectedProductionTypes, setErrorSelectedProductionTypes] = useState(false)
    const [errorSelectedItems, setErrorSelectedItems] = useState(false)

    const [csv, setCsv] = useState(false)
    const [xlsx, setXlsx] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const [openPurchaseAddresses, setOpenPurchaseAddresses] = useState(false)
    const [openShipAddresses, setOpenShipAddresses] = useState(false)
    const [openProductionTypes, setOpenProductionTypes] = useState(false)
    const [openItems, setOpenItems] = useState(false)

    /* Effect(s) */

    useEffect(() => {
        if (!startDate.isSame(endDate) && startDate.isAfter(endDate)) {
            setEndDate(startDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        setVendors(sortByProperty(companyVendors, 'name'))
        setCustomers(sortByProperty(companyCustomers, 'name'))
        setDrivers(sortByProperty(companyDrivers, 'name'))
        setForklifters(sortByProperty(companyForklifters, 'name'))
        setUsers(companyUsers)
        setSites(sortByProperty(companySites, 'name'))
        setProductionTypes(sortByProperty(companyProductionTypes, 'code'))
        setProductionEmployees(sortByProperty(companyProductionEmployees, 'name'))
    }, [companyVendors, companyCustomers, companyDrivers, companyForklifters, companyUsers, companySites, companyProductionTypes, companyProductionEmployees])

    useEffect(() => {
        setPurchaseAddress('')
        setPurchaseAddressName('')
        setSelectedPurchaseAddresses([])
        setErrorPurchaseAddress(false)
        setErrorSelectedPurchaseAddresses(false)
        if (vendors.length > 0 && vendor !== null && vendor !== '') {
            const target = vendors.find((v) => v.id === vendor)
            if (target) {
                setPurchaseAddresses(sortByProperty(target.purchase_addresses, 'name'))
            } else {
                setPurchaseAddresses([])
            }
        }
    }, [vendors, vendor])

    useEffect(() => {
        setShipToAddress('')
        setShipToAddressName('')
        setSelectedShipToAddresses([])
        setErrorShipToAddress(false)
        setErrorSelectedShipToAddresses(false)
        if (customers.length > 0 && customer !== null && customer !== '') {
            const target = customers.find((c) => c.id === customer)
            if (target) {
                setShipToAddresses(sortByProperty(target.ship_addresses, 'name'))
            } else {
                setShipToAddresses([])
            }
        }
    }, [customers, customer])

    useState(() => {
        setDriver('')
        setDriverName('')
        setErrorDriver(false)
    }, [drivers])

    useState(() => {
        setForklifter('')
        setForklifterName('')
        setErrorForklifter(false)
    }, [forklifters])

    useState(() => {
        setUser('')
        setUserName('')
        setErrorUser(false)
    }, [users])

    useState(() => {
        setProductionEmployee('')
        setProductionEmployeeName('')
        setErrorProductionEmployee(false)
    }, [productionEmployees])

    useState(() => {
        setSelectedProductionTypes([])
        setErrorSelectedProductionTypes(false)
        setSelectedItems([])
        setErrorSelectedItems(false)
    }, [productionTypes])

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false)

            setStartDate(dayjs())
            setEndDate(dayjs())

            setVendor('')
            setVendorName('')
            setCustomer('')
            setCustomerName('')
            setDriver('')
            setDriverName('')
            setForklifter('')
            setForklifterName('')
            setUser('')
            setUserName('')
            setErrorUser(false)
            setProductionEmployee('')
            setProductionEmployeeName('')
            setSite('')
            setSiteName('')
            setPurchaseAddress('')
            setPurchaseAddressName('')
            setShipToAddress('')
            setShipToAddressName('')
            setSelectedPurchaseAddresses([])
            setSelectedPurchaseAddresses([])
            setSelectedProductionTypes([])
            setSelectedItems([])

            setErrorVendor(false)
            setErrorCustomer(false)
            setErrorDriver(false)
            setErrorForklifter(false)
            setErrorProductionEmployee(false)
            setErrorSite(false)
            setErrorPurchaseAddress(false)
            setErrorShipToAddress(false)
            setErrorSelectedShipToAddresses(false)
            setErrorSelectedPurchaseAddresses(false)
            setErrorSelectedProductionTypes(false)
            setErrorSelectedItems(false)

            setCsv(false)
            setXlsx(false)

            setOpenPurchaseAddresses(false)
            setOpenShipAddresses(false)
            setOpenProductionTypes(false)
            setOpenItems(false)
            onClose()
        }
    }

    const handleChangeCsv = (e) => {
        setCsv(e.target.checked)
    }

    const handleChangeXlsx = (e) => {
        setXlsx(e.target.checked)
    }

    const handleChangePurchaseAddressesSelection = (event, values) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value.forEach((paddress) => {
            if (duplicateRemoved.findIndex((o) => o.id === paddress.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === paddress.id)
            } else {
                duplicateRemoved.push(paddress)
            }
        })

        if (errorSelectedPurchaseAddresses) setErrorSelectedPurchaseAddresses(false)

        if (value[value.length - 1] === 'all') {
            setSelectedPurchaseAddresses(selectedPurchaseAddresses.length === purchaseAddresses.length ? [] : purchaseAddresses)
            return
        }

        setSelectedPurchaseAddresses(duplicateRemoved)
    }

    const handleChangeShipToAddressesSelection = (event, values) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value.forEach((saddress) => {
            if (duplicateRemoved.findIndex((o) => o.id === saddress.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === saddress.id)
            } else {
                duplicateRemoved.push(saddress)
            }
        })

        if (errorSelectedShipToAddresses) setSelectedShipToAddresses(false)

        if (value[value.length - 1] === 'all') {
            setSelectedShipToAddresses(selectedShipToAddresses.length === shipToAddresses.length ? [] : shipToAddresses)
            return
        }

        setSelectedShipToAddresses(duplicateRemoved)
    }

    const handleChangeProductionTypesSelection = (event, values) => {
        const { target: { value } } = event
        let duplicateRemoved = []
        let itemOptions = []

        setSelectedItems([])

        value.forEach((pt) => {
            const typeItems = pt.items
            const typeItemIds = typeItems?.map((ti) => ti.id)

            if (duplicateRemoved.findIndex((o) => o.id === pt.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === pt.id)
                if (typeItemIds) itemOptions = itemOptions.filter((y) => typeItemIds.includes(y.id))
            } else {
                duplicateRemoved.push(pt)
                if (typeItems) itemOptions = concat(itemOptions, typeItems)
            }
        })

        if (errorSelectedProductionTypes) setErrorSelectedProductionTypes(false)
        if (errorSelectedItems) setErrorSelectedItems(false)

        if (value[value.length - 1] === 'all') {
            const allProductionTypes = selectedProductionTypes.length === productionTypes.length ? [] : productionTypes
            setSelectedProductionTypes(allProductionTypes)
            if (allProductionTypes.length > 0) {
                itemOptions = []
                allProductionTypes.forEach((pt) => {
                    const typeItems = pt.items
                    itemOptions = concat(itemOptions, typeItems)
                })
                setSelectedItems([])
                setItems(sortByProperty(itemOptions, 'item_no'))
            } else {
                setSelectedItems([])
                setItems([])
            }
            return
        }

        setSelectedProductionTypes(duplicateRemoved)
        setSelectedItems([])
        setItems(sortByProperty(itemOptions, 'item_no'))
    }

    const handleChangeItemsSelection = (event, values) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id)
            } else {
                duplicateRemoved.push(item)
            }
        })

        if (errorSelectedItems) setErrorSelectedItems(false)

        if (value[value.length - 1] === 'all') {
            setSelectedItems(selectedItems.length === items.length ? [] : items)
            return
        }

        setSelectedItems(duplicateRemoved)
    }

    const handleDeleteSelection = (e, id, optionType) => {
        let shouldDelete;
        switch (optionType) {
            case "purchase_address":
                shouldDelete = selectedPurchaseAddresses.find((x) => x.id === id)
                if (shouldDelete) {
                    const filtered = selectedPurchaseAddresses.filter((x) => x.id !== shouldDelete.id)
                    if (filtered.length > 0) {
                        setSelectedPurchaseAddresses(filtered)
                    } else {
                        setSelectedPurchaseAddresses([])
                        setOpenPurchaseAddresses(false)
                    }
                }
                break
            case "ship_address":
                shouldDelete = selectedShipToAddresses.find((x) => x.id === id)
                if (shouldDelete) {
                    const filtered = selectedShipToAddresses.filter((x) => x.id !== shouldDelete.id)
                    if (filtered.length > 0) {
                        setSelectedShipToAddresses(filtered)
                    } else {
                        setSelectedShipToAddresses([])
                        setOpenShipAddresses(false)
                    }
                }
                break
            case "production_type":
                shouldDelete = selectedProductionTypes.find((x) => x.id === id)
                let itemOptions = []
                if (shouldDelete) {
                    const filtered = selectedProductionTypes.filter((x) => x.id !== shouldDelete.id)
                    if (filtered.length > 0) {
                        filtered.forEach(pt => {
                            const typeItems = pt.items
                            if (typeItems) itemOptions = concat(itemOptions, typeItems)
                        });
                        setSelectedItems([])
                        setItems(sortByProperty(itemOptions, 'item_no'))
                        setSelectedProductionTypes(filtered)
                    } else {
                        setSelectedItems([])
                        setSelectedProductionTypes([])
                        setOpenProductionTypes(false)
                    }
                }
                break
            case "item":
                shouldDelete = selectedItems.find((x) => x.id === id)
                if (shouldDelete) {
                    const filtered = selectedItems.filter((x) => x.id !== shouldDelete.id)
                    if (filtered.length > 0) {
                        setSelectedItems(filtered)
                    } else {
                        setSelectedItems([])
                        setOpenItems(false)
                    }
                }
                break
            default:
                return
        }
    }

    const getReport = async (e) => {
        let uri = '/'
        let exportFileName = 'report'
        let allClear = false
        const targetedSite = localStorage.getItem('site') ? localStorage.getItem('site') : choosesite
        const targetedSiteName = snakeCase(allSites?.find((site) => Number(site['id']) == Number(targetedSite))['name'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') ?? 'Site Name')
        const targetedCompanyName = snakeCase(allSites?.find((site) => Number(site['id']) == Number(targetedSite))['company']['name'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') ?? 'Company Name')

        switch (reportCode) {
            case REPORT_CODES.collections.COMPLETED_COLLECTIONS_BY_VENDOR:
                if (!vendor) {
                    setErrorVendor(true)
                } else if (!purchaseAddress) {
                    setErrorPurchaseAddress(true)
                } else if (!vendor && !purchaseAddress) {
                    setErrorVendor(true)
                    setErrorPurchaseAddress(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('collection_reports.ccbv') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/collections/completed-collections?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&vendor=${vendor}&purchase_address=${purchaseAddress}`
                    allClear = true
                }
                break
            case REPORT_CODES.collections.ONSITE_COLLECTIONS_AWAITING_UNLOAD:
                exportFileName = `${snakeCase(t('x_report', { x: t('collection_reports.oscau') }))} ${dayjs().format('HH-mm-ss_YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/collections/onsite-collections?site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.collections.SITE_COLLECTION_SUMMARY:
                if (!site) {
                    setErrorSite(true)
                } else {
                    const collectionSiteName = snakeCase(allSites.find((s) => Number(s['id']) == Number(site))['name'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') ?? 'Site Name')
                    exportFileName = `${snakeCase(t('x_report', { x: t('collection_reports.scs') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${collectionSiteName}`
                    uri = `/api/reports/collections/site-collection-summary?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${site}`
                    allClear = true
                }
                break
            case REPORT_CODES.collections.ONTIME_COLLECTIONS:
                if (!vendor) {
                    setErrorVendor(true)
                } else if (selectedPurchaseAddresses.length < 1) {
                    setErrorSelectedPurchaseAddresses(true)
                } else if (!vendor && selectedPurchaseAddresses.length < 1) {
                    setErrorVendor(true)
                    setErrorSelectedPurchaseAddresses(true)
                } else {
                    let paids = ''
                    if (selectedPurchaseAddresses.length == 1) {
                        paids = selectedPurchaseAddresses[0].id;
                    } else if (selectedPurchaseAddresses.length > 1) {
                        for (let i = 0; i < selectedPurchaseAddresses.length; i++) {
                            if (i == 0) {
                                paids = selectedPurchaseAddresses[i].id
                            } else {
                                paids += `,${selectedPurchaseAddresses[i].id}`
                            }
                        }
                    }
                    exportFileName = `${snakeCase(t('x_report', { x: t('collection_reports.otc') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/collections/ontime-collections?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&vendor=${vendor}&purchase_addresses=${paids}`
                    allClear = true
                }
                break
            case REPORT_CODES.shipments.COMPLETED_SHIPMENTS:
                exportFileName = `${snakeCase(t('x_report', { x: t('shipment_reports.cs') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/shipments/completed-shipments?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.shipments.LOADED_OUTBOUND:
                exportFileName = `${snakeCase(t('x_report', { x: t('shipment_reports.lo') }))} ${dayjs().format('HH-mm-ss_YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/shipments/loaded-outbound?site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.shipments.SITE_SHIPMENT_SUMMARY:
                if (!site) {
                    setErrorSite(true)
                } else {
                    const shipmentSiteName = snakeCase(allSites.find((s) => Number(s['id']) == Number(site))['name'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') ?? 'Site Name')
                    exportFileName = `${snakeCase(t('x_report', { x: t('collection_reports.scs') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${shipmentSiteName}`
                    uri = `/api/reports/shipments/open-shipments?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${site}`
                    allClear = true
                }
                break
            case REPORT_CODES.shipments.ONTIME_DELIVERIES:
                if (!customer) {
                    setErrorCustomer(true)
                } else if (selectedShipToAddresses.length < 1) {
                    setErrorSelectedShipToAddresses(true)
                } else if (!customer && selectedShipToAddresses.length < 1) {
                    setErrorCustomer(true)
                    setErrorSelectedShipToAddresses(true)
                } else {
                    let staids = ''
                    if (selectedShipToAddresses.length == 1) {
                        staids = selectedShipToAddresses[0].id;
                    } else if (selectedShipToAddresses.length > 1) {
                        for (let i = 0; i < selectedShipToAddresses.length; i++) {
                            if (i == 0) {
                                staids = selectedShipToAddresses[i].id
                            } else {
                                staids += `,${selectedShipToAddresses[i].id}`
                            }
                        }
                    }
                    exportFileName = `${snakeCase(t('x_report', { x: t('shipment_reports.otd') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/shipments/ontime-deliveries?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&customer=${customer}&ship_to_addresses=${staids}`
                    allClear = true
                }
                break
            case REPORT_CODES.activities.DRIVER_ACTIVITY_REPORT:
                if (!driver) {
                    setErrorDriver(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.dar') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/activities/driver-activity?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&driver=${driver}`
                    allClear = true
                }
                break
            case REPORT_CODES.activities.DRIVER_PRODUCTIVITY_REPORT:
                exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.dpr') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/activities/driver-productivity?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.activities.FORKLIFT_DRIVER_ACTIVITY_REPORT:
                if (!forklifter) {
                    setErrorForklifter(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.fdar') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/activities/forklifter-activity?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&forklifter=${forklifter}`
                    allClear = true
                }
                break
            case REPORT_CODES.activities.FORKLIFT_DRIVER_PRODUCTIVITY_REPORT:
                exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.fdpr') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/activities/forklifter-productivity?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_VENDOR:
                if (!vendor) {
                    setErrorVendor(true)
                } else if (!purchaseAddress) {
                    setErrorPurchaseAddress(true)
                } else if (!vendor && !purchaseAddress) {
                    setErrorVendor(true)
                    setErrorPurchaseAddress(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.thsbv') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/activities/vendor-ths?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&vendor=${vendor}&purchase_address=${purchaseAddress}`
                    allClear = true
                }
                break
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_CUSTOMER:
                if (!customer) {
                    setErrorCustomer(true)
                } else if (!shipToAddress) {
                    setErrorShipToAddress(true)
                } else if (!customer && !shipToAddress) {
                    setErrorCustomer(true)
                    setErrorShipToAddress(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.thsbc') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/activities/customer-ths?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&customer=${customer}&ship_to_address=${shipToAddress}`
                    allClear = true
                }
                break
            case REPORT_CODES.activities.SYSTEM_USAGE_BY_USER:
                if (!user) {
                    setErrorUser(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('activity_reports.subu') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/activities/portal-usage?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&user=${user}`
                    allClear = true
                }
                break
            case REPORT_CODES.production.PRODUCTION_SUMMARY:
                exportFileName = `${snakeCase(t('x_report', { x: t('production_reports.ps') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/production/production-summary?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}`
                allClear = true
                break
            case REPORT_CODES.production.PRODUCTION_ACTIVITY_BY_DAY:
                if (selectedProductionTypes.length < 1) {
                    setErrorSelectedProductionTypes(true)
                } else if (selectedItems.length < 1) {
                    setErrorSelectedItems(true)
                } else if (selectedProductionTypes.length < 1 && selectedItems.length < 1) {
                    setErrorSelectedProductionTypes(true)
                    setErrorSelectedItems(true)
                } else {
                    let ptids = ''
                    let iids = ''

                    if (selectedProductionTypes.length == 1) {
                        ptids = selectedProductionTypes[0].id;
                    } else if (selectedProductionTypes.length > 1) {
                        for (let i = 0; i < selectedProductionTypes.length; i++) {
                            if (i == 0) {
                                ptids = selectedProductionTypes[i].id
                            } else {
                                ptids += `,${selectedProductionTypes[i].id}`
                            }
                        }
                    }

                    if (selectedItems.length == 1) {
                        iids = selectedItems[0].id
                    } else if (selectedItems.length > 1) {
                        for (let i = 0; i < selectedItems.length; i++) {
                            if (i == 0) {
                                iids = selectedItems[i].id
                            } else {
                                iids += `,${selectedItems[i].id}`
                            }
                        }
                    }
                    exportFileName = `${snakeCase(t('x_report', { x: t('production_reports.pabd') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/production/production-activity-by-day?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}&production_types=${ptids}&items=${iids}`
                    allClear = true
                }
                break
            case REPORT_CODES.production.PRODUCTION_EMPLOYEE_ACTIVITY_BY_DAY:
                if (!productionEmployee) {
                    setErrorProductionEmployee(true)
                } else {
                    exportFileName = `${snakeCase(t('x_report', { x: t('production_reports.peabd') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                    uri = `/api/reports/production/production-employee-activity?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&employee=${productionEmployee}`
                    allClear = true
                }
                break
            case REPORT_CODES.production.PRODUCTION_SUMMARY_REPORT_BY_PRODUCTION_EMPLOYEE:
                exportFileName = `${snakeCase(t('x_report', { x: t('production_reports.psrbpe') }))} ${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')} ${targetedCompanyName}-${targetedSiteName}`
                uri = `/api/reports/production/production-employee-summary?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}&site=${targetedSite}`
                allClear = true
                break
            default:
        }

        if (allClear) {
            const uriWithClientTimezone = `${uri}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`
            const todos = []

            if (csv) {
                todos.push(getCsv)
            }

            if (xlsx) {
                todos.push(getXlsx)
            }

            todos.push(getStream)

            try {
                setIsLoading(true)
                for (const func of todos) {
                    await func(uriWithClientTimezone, exportFileName)
                }
            } catch (error) {
                Swal.fire({
                    title: error.message ?? t('something_went_wrong'),
                    text: error.description ?? t('contact_support_message'),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } finally {
                setIsLoading(false)
            }

            //handleCloseModal(e, "reportRequest")
        }
    }

    /* Utility Functions */

    const getCsv = async (uri, exportFileName) => {
        await axios.get(process.env.REACT_APP_BACKEND_URL + uri + '&export=csv', {
            ...config,
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob(
                [response.data],
                { type: 'text/csv' }
            ))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${exportFileName}.csv`)
            document.body.appendChild(link)
            link.click()
        }).catch(({ response }) => {
            throw {message: extractErrorMessage(response), description: extractErrorDescription(response)}
        })
    }

    const getXlsx = async (uri, exportFileName) => {
        await axios.get(process.env.REACT_APP_BACKEND_URL + uri + '&export=xlsx', {
            ...config,
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob(
                [response.data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            ))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${exportFileName}.xlsx`)
            document.body.appendChild(link)
            link.click()
        }).catch(({ response }) => {
            throw {message: extractErrorMessage(response), description: extractErrorDescription(response)}
        })
    }

    const getStream = async (uri) => {
        await axios.get(process.env.REACT_APP_BACKEND_URL + uri, {
            ...config,
            responseType: 'blob'
        }).then((response) => {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' })

            const fileUrl = URL.createObjectURL(file)
            window.open(fileUrl)
        }).catch(({ response }) => {
            throw {message: extractErrorMessage(response), description: extractErrorDescription(response)}
        })
    }

    /* Utility Components */

    // Not recommended to be used for wrapping multiselects. It appears to hinder their disableCloseOnSelect functionality.
    const FormWrapper = ({ children }) => {
        return (
            <form className='flex flex-col gap-3 my-5 pb-5'>
                {children}
            </form>
        )
    }

    const renderDatePickers = () => {
        return (
            <div className='flex flex-row w-full gap-3'>
                <FormControl className='w-1/2'>
                    <p className="text-[14px] text-[#718096]">{t('start_date')}</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            id="select-start-date"
                            sx={{
                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                            }}
                            format="YYYY-MM-DD" disabled={disabled}
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl className='w-1/2'>
                    <p className="text-[14px] text-[#718096]">{t('end_date')}</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            minDate={startDate}
                            id="select-end-date"
                            sx={{
                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                            }}
                            format="YYYY-MM-DD" disabled={disabled}
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </LocalizationProvider>
                </FormControl>
            </div>
        )
    }

    const renderUserSelectDropdown = () => {
        const options = [
            {
                label: currentUser?.name,
                id: currentUser?.id
            },
            ...users?.map(option => ({ label: option.name, id: option.id }))
        ]

        return (
            <>
                <p className="text-[14px] text-[#718096]">{t('user')}</p>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{
                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                    }}
                    value={user ? { label: userName, id: user } : null}
                    ListboxProps={{
                        style: {
                            maxHeight: '300px'
                        }
                    }}
                    onChange={(a, b) => { setUser(b?.id || ''); setUserName(b?.label || ''); setErrorUser(false) }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </>
        )
    }

    const renderModalBody = () => {

        const optionsVendor = vendors?.map(option => ({ label: option.name, id: option.id }))
        const optionsPa = purchaseAddresses?.map(option => ({ label: option.name, id: option.id }))
        const optionsCustomer = customers?.map(option => ({ label: option.name, id: option.id }))
        const optionsSa = shipToAddresses?.map(option => ({ label: option.name, id: option.id }))
        const optionsSite = sites?.map(option => ({ label: option.name, id: option.id }))
        const optionsDrivers = drivers?.map(option => ({ label: option.name, id: option.id }))
        const optionsForklifters = forklifters?.map(option => ({ label: option.name, id: option.id }))
        const optionsEmployees = productionEmployees.map(option => ({ label: option.name, id: option.id }))

        switch (reportCode) {
            case REPORT_CODES.collections.COMPLETED_COLLECTIONS_BY_VENDOR:
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_VENDOR:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorVendor} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsVendor}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={vendor ? { label: vendorName, id: vendor } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setVendor(b?.id || ''); setVendorName(b?.label || ''); setErrorVendor(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorVendor ? (
                                <FormHelperText>{t('please_choose_the_vendor')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl error={errorPurchaseAddress} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('purchase_location')}</p>
                            <Autocomplete
                                disablePortal
                                disabled={vendor === ''}
                                id="combo-box-demo"
                                options={optionsPa}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={purchaseAddress ? { label: purchaseAddressName, id: purchaseAddress } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setPurchaseAddress(b?.id || ''); setPurchaseAddressName(b?.label || ''); setErrorPurchaseAddress(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorPurchaseAddress ? (
                                <FormHelperText>{t('please_choose_the_purchase_location')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_CUSTOMER:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorCustomer} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('customer')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsCustomer}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={customer ? { label: customerName, id: customer } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setCustomer(b?.id || ''); setCustomerName(b?.label || ''); setErrorCustomer(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorCustomer ? (
                                <FormHelperText>{t('please_choose_the_customer')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl error={errorShipToAddress} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('ship_to_location')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsSa}
                                disabled={customer === ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={shipToAddress ? { label: shipToAddressName, id: shipToAddress } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setShipToAddress(b?.id || ''); setShipToAddressName(b?.label || ''); setErrorShipToAddress(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorShipToAddress ? (
                                <FormHelperText>{t('please_choose_the_ship_to_location')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.collections.SITE_COLLECTION_SUMMARY:
            case REPORT_CODES.shipments.SITE_SHIPMENT_SUMMARY:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorSite} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('site')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsSite}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={site ? { label: siteName, id: site } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setSite(b?.id || ''); setSiteName(b?.label || ''); setErrorSite(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorSite ? (
                                <FormHelperText>{t('please_choose_the_site')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.collections.ONTIME_COLLECTIONS:
                return (
                    <form className='flex flex-col gap-5 my-5 pb-5'>
                        {renderDatePickers()}
                        <FormControl error={errorVendor} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsVendor}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={vendor ? { label: vendorName, id: vendor } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setVendor(b?.id || ''); setVendorName(b?.label || ''); setErrorVendor(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorVendor ? (
                                <FormHelperText>{t('please_choose_the_vendor')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl error={errorSelectedPurchaseAddresses} className='w-full' focused={openPurchaseAddresses}>

                            <p className="text-[14px] text-[#718096]">{t('purchase_name')}</p>
                            <Select
                                labelId='select-purchase-locations-label'
                                id="select-purchase-locations"
                                disabled={vendor === ''}
                                open={openPurchaseAddresses}
                                onOpen={() => setOpenPurchaseAddresses(true)}
                                onClose={() => setOpenPurchaseAddresses(false)}
                                multiple
                                value={selectedPurchaseAddresses}
                                MenuProps={{
                                    disableScrollLock: true,
                                    marginThreshold: null,
                                    style: {
                                        maxHeight: "275px",
                                        height: "40vh",
                                    }
                                }}
                                onChange={handleChangePurchaseAddressesSelection}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`pa_option-${x.id}`}
                                                label={x.name}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelection(e, x.id, 'purchase_address')}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' },
                                    ".MuiSelect-iconOutlined": { display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : '' }
                                }}
                                endAdornment={(vendor && selectedPurchaseAddresses) ? (<IconButton sx={{ visibility: (selectedPurchaseAddresses.length) > 0 ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedPurchaseAddresses([])}><ClearIcon /></IconButton>) : false}
                            >

                                {
                                    purchaseAddresses?.length > 0 ?
                                        <MenuItem value='all'>
                                            <ListItemIcon>
                                                <Checkbox checked={everyPurchaseAddressSelected} indeterminate={selectedPurchaseAddresses.length > 0 && selectedPurchaseAddresses.length < purchaseAddresses.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    purchaseAddresses.map((paddress) =>
                                        <MenuItem value={paddress} key={`paid${paddress.id}`}>
                                            <Checkbox checked={selectedPurchaseAddresses?.findIndex((i) => i.id === paddress.id) >= 0} />
                                            <ListItemText primary={paddress.name} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorSelectedPurchaseAddresses ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('purchase_location')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </form>
                )
            case REPORT_CODES.shipments.ONTIME_DELIVERIES:
                return (
                    <form className='flex flex-col gap-5 my-5 pb-5'>
                        {renderDatePickers()}
                        <FormControl error={errorCustomer} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('customer')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsCustomer}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={customer ? { label: customerName, id: customer } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setCustomer(b?.id || ''); setCustomerName(b?.label || ''); setErrorCustomer(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {errorCustomer ? (
                                <FormHelperText>{t('please_choose_the_customer')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl error={errorSelectedShipToAddresses} className='w-full' focused={openShipAddresses}>
                            <p className="text-[14px] text-[#718096]">{t('ship_to_name')}</p>
                            <Select
                                labelId='select-ship-to-locations-label'
                                id="select-ship-to-locations"
                                disabled={customer === ''}
                                open={openShipAddresses}
                                onOpen={() => setOpenShipAddresses(true)}
                                onClose={() => setOpenShipAddresses(false)}
                                multiple
                                value={selectedShipToAddresses}
                                MenuProps={{
                                    disableScrollLock: true,
                                    marginThreshold: null,
                                    style: {
                                        maxHeight: "275px",
                                        height: "40vh",
                                    }
                                }}
                                onChange={handleChangeShipToAddressesSelection}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`sta_option-${x.id}`}
                                                label={x.name}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelection(e, x.id, 'ship_address')}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' },
                                    ".MuiSelect-iconOutlined": { display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : '' }
                                }}
                                endAdornment={(customer && selectedShipToAddresses) ? (<IconButton sx={{ visibility: (selectedShipToAddresses.length) > 0 ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedShipToAddresses([])}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    shipToAddresses?.length > 0 ?
                                        < MenuItem value='all'>
                                            <ListItemIcon>
                                                <Checkbox checked={everyShipToAddressSelected} indeterminate={selectedShipToAddresses.length > 0 && selectedShipToAddresses.length < shipToAddresses.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    shipToAddresses.map((saddress) =>
                                        <MenuItem value={saddress} key={`staid${saddress.id}`}>
                                            <Checkbox checked={selectedShipToAddresses?.findIndex((i) => i.id === saddress.id) >= 0} />
                                            <ListItemText primary={saddress.name} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorSelectedShipToAddresses ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('ship_to_location')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </form >
                )
            case REPORT_CODES.activities.DRIVER_ACTIVITY_REPORT:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorDriver} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('driver')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsDrivers}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={driver ? { label: driverName, id: driver } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setDriver(b?.id || ''); setDriverName(b?.label || ''); setErrorDriver(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorDriver ? (
                                <FormHelperText>{t('please_choose_the_driver')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.activities.FORKLIFT_DRIVER_ACTIVITY_REPORT:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorForklifter} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('forklift_driver')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsForklifters}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={forklifter ? { label: forklifterName, id: forklifter } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setForklifter(b?.id || ''); setForklifterName(b?.label || ''); setErrorForklifter(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorForklifter ? (
                                <FormHelperText>{t('please_choose_the_forklift_driver')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.activities.SYSTEM_USAGE_BY_USER:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorUser} className='w-full'>
                            {renderUserSelectDropdown()}
                            {errorUser ? (
                                <FormHelperText>{t('please_choose_the_user')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.production.PRODUCTION_ACTIVITY_BY_DAY:
                return (
                    <form className='flex flex-col gap-5 my-5 pb-5'>
                        {renderDatePickers()}
                        <FormControl error={errorSelectedProductionTypes} className='w-full' focused={openProductionTypes}>
                            <p className="text-[14px] text-[#718096]">{t('production_type')}</p>
                            <Select
                                labelId='select-production-types-label'
                                id="select-production-types"
                                open={openProductionTypes}
                                onOpen={() => setOpenProductionTypes(true)}
                                onClose={() => setOpenProductionTypes(false)}
                                multiple
                                value={selectedProductionTypes}
                                MenuProps={{
                                    disableScrollLock: true,
                                    marginThreshold: null,
                                    style: {
                                        maxHeight: "300px",
                                        height: "45vh",
                                    }
                                }}
                                onChange={handleChangeProductionTypesSelection}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`pt_option-${x.id}`}
                                                label={x.code}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelection(e, x.id, 'production_type')}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' },
                                    ".MuiSelect-iconOutlined": { display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : '' }
                                }}
                                endAdornment={selectedProductionTypes ? (<IconButton sx={{ visibility: (selectedProductionTypes.length) > 0 ? "visible" : "hidden", padding: '0' }} onClick={() => { setSelectedProductionTypes([]); setItems([]); setSelectedItems([]) }}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    productionTypes?.length > 0 ?
                                        <MenuItem value='all'>
                                            <ListItemIcon>
                                                <Checkbox checked={everyProductionTypeSelected} indeterminate={selectedProductionTypes.length > 0 && selectedProductionTypes.length < productionTypes.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    productionTypes.map((ptype) =>
                                        <MenuItem value={ptype} key={`pt${ptype.id}`}>
                                            <Checkbox checked={selectedProductionTypes?.findIndex((i) => i.id === ptype.id) >= 0} />
                                            <ListItemText primary={ptype.code} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorSelectedProductionTypes ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('production_type')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl error={errorSelectedItems} className='w-full' focused={openItems}>
                            <p className="text-[14px] text-[#718096]">{t('item')}</p>
                            <Select
                                labelId='select-items-label'
                                id="select-items"
                                disabled={selectedProductionTypes.length < 1}
                                open={openItems}
                                onOpen={() => setOpenItems(true)}
                                onClose={() => setOpenItems(false)}
                                multiple
                                value={selectedItems}
                                MenuProps={{
                                    disableScrollLock: true,
                                    marginThreshold: null,
                                    style: {
                                        maxHeight: "275px",
                                        height: "40vh",
                                    }
                                }}
                                onChange={handleChangeItemsSelection}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`pi_option-${x.id}`}
                                                label={x.item_no}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelection(e, x.id, 'item')}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' },
                                    ".MuiSelect-iconOutlined": { display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : '' }
                                }}
                                endAdornment={(selectedProductionTypes && selectedItems) ? (<IconButton sx={{ visibility: (selectedItems.length) > 0 ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedItems([])}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    items?.length > 0 ?
                                        <MenuItem value='all'>
                                            <ListItemIcon>
                                                <Checkbox checked={everyItemSelected} indeterminate={selectedItems.length > 0 && selectedItems.length < items.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    items.map((item) =>
                                        <MenuItem value={item} key={`i${item.id}`}>
                                            <Checkbox checked={selectedItems?.findIndex((i) => i.id === item.id) >= 0} />
                                            <ListItemText primary={item.item_no} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorSelectedItems ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('item')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </form>
                )
            case REPORT_CODES.production.PRODUCTION_EMPLOYEE_ACTIVITY_BY_DAY:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                        <FormControl error={errorProductionEmployee} className='w-full'>
                            <p className="text-[14px] text-[#718096]">{t('employee')}</p>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsEmployees}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }}
                                value={productionEmployee ? { label: productionEmployeeName, id: productionEmployee } : null}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '300px'
                                    }
                                }}
                                onChange={(a, b) => { setProductionEmployee(b?.id || ''); setProductionEmployeeName(b?.label || ''); setErrorProductionEmployee(false) }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            {errorProductionEmployee ? (
                                <FormHelperText>{t('please_choose_the_employee')}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </FormWrapper>
                )
            case REPORT_CODES.shipments.COMPLETED_SHIPMENTS:
            case REPORT_CODES.activities.DRIVER_PRODUCTIVITY_REPORT:
            case REPORT_CODES.activities.FORKLIFT_DRIVER_PRODUCTIVITY_REPORT:
            case REPORT_CODES.production.PRODUCTION_SUMMARY:
            case REPORT_CODES.production.PRODUCTION_SUMMARY_REPORT_BY_PRODUCTION_EMPLOYEE:
                return (
                    <FormWrapper>
                        {renderDatePickers()}
                    </FormWrapper>
                )
            default:
                return ''
        }
    }

    const getModalHeaderName = () => {
        switch (reportCode) {
            case REPORT_CODES.collections.COMPLETED_COLLECTIONS_BY_VENDOR:
                return `${t('x_report', { x: t('collection_reports.ccbv') })}`
            case REPORT_CODES.collections.ONSITE_COLLECTIONS_AWAITING_UNLOAD:
                return `${t('x_report', { x: t('collection_reports.oscau') })}`
            case REPORT_CODES.collections.SITE_COLLECTION_SUMMARY:
                return `${t('x_report', { x: t('collection_reports.scs') })}`
            case REPORT_CODES.collections.ONTIME_COLLECTIONS:
                return `${t('x_report', { x: t('collection_reports.otc') })}`
            case REPORT_CODES.shipments.COMPLETED_SHIPMENTS:
                return `${t('x_report', { x: t('shipment_reports.cs') })}`
            case REPORT_CODES.shipments.LOADED_OUTBOUND:
                return `${t('x_report', { x: t('shipment_reports.lo') })}`
            case REPORT_CODES.shipments.SITE_SHIPMENT_SUMMARY:
                return `${t('x_report', { x: t('shipment_reports.sss') })}`
            case REPORT_CODES.shipments.ONTIME_DELIVERIES:
                return `${t('x_report', { x: t('shipment_reports.otd') })}`
            case REPORT_CODES.activities.DRIVER_ACTIVITY_REPORT:
                return `${t('x_report', { x: t('activity_reports.dar') })}`
            case REPORT_CODES.activities.DRIVER_PRODUCTIVITY_REPORT:
                return `${t('x_report', { x: t('activity_reports.dpr') })}`
            case REPORT_CODES.activities.FORKLIFT_DRIVER_ACTIVITY_REPORT:
                return `${t('x_report', { x: t('activity_reports.fdar') })}`
            case REPORT_CODES.activities.FORKLIFT_DRIVER_PRODUCTIVITY_REPORT:
                return `${t('x_report', { x: t('activity_reports.fdpr') })}`
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_VENDOR:
                return `${t('x_report', { x: t('activity_reports.thsbv') })}`
            case REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_CUSTOMER:
                return `${t('x_report', { x: t('activity_reports.thsbc') })}`
            case REPORT_CODES.activities.SYSTEM_USAGE_BY_USER:
                return `${t('x_report', { x: t('activity_reports.subu') })}`
            case REPORT_CODES.production.PRODUCTION_SUMMARY:
                return `${t('x_report', { x: t('production_reports.ps') })}`
            case REPORT_CODES.production.PRODUCTION_ACTIVITY_BY_DAY:
                return `${t('x_report', { x: t('production_reports.pabd') })}`
            case REPORT_CODES.production.PRODUCTION_EMPLOYEE_ACTIVITY_BY_DAY:
                return `${t('x_report', { x: t('production_reports.peabd') })}`
            case REPORT_CODES.production.PRODUCTION_SUMMARY_REPORT_BY_PRODUCTION_EMPLOYEE: // !!!NEEDS TO BE UPDATED EVENTUALLY, ONCE IT HAS BEEN DETERMINED WHAT THIS REPORT IS ACTUALLY REPORTING!!!
                return `${t('x_report', { x: t('production_reports.psrbpe') })}`
            default:
                return `${t('report')}`
        }
    }

    /* Main Component */

    return (
        <Modal open={open} onClose={(event, reason) => handleCloseModal(event, reason)}>
            <Box sx={style}>
                <div className='p-5'>
                    {/* The header of the modal. Will always be shown regardless of the type of report requested. Contains Exit button. */}

                    <div className='flex justify-between items-center pb-5 w-full' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-baseline justify-between w-full'>
                            <p className='text-[18px] font-[600] text-[#303030]'>{getModalHeaderName()}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[18px] text-[#303030]">
                                <button onClick={handleCloseModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>


                    {renderModalBody()}

                    {/* The footer of the modal. Will always be shown regardless of the type of report requested. */}
                    <div className="flex justify-between gap-5 my-5">
                        <FormControl component="fieldset" variant='standard'>
                            <FormGroup sx={{ flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox disabled={false} checked={csv} onChange={(e) => handleChangeCsv(e)} name="csv" />
                                    }
                                    label="csv"
                                    sx={{ color: '#5B5F64' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox disabled={false} checked={xlsx} onChange={(e) => handleChangeXlsx(e)} name="xlsx" />
                                    }
                                    label="xlsx"
                                    sx={{ color: '#5B5F64' }}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>

                    <div className='w-full flex gap-2 justify-between'>
                        <button type="button" onClick={handleCloseModal} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                            {t('cancel')}
                        </button>
                        <button type="button" onClick={getReport} disabled={disabled} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#6C6C6C] hover:bg-[#494949] disabled:bg-[#B8B7BC] capitalize'>
                            {t('generate')}
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ReportModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "600px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
