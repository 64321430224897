import React, { useEffect, useState } from 'react'

import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

import AuthCard from '../../components/AuthCard'
import AuthSessionStatus from '../../components/AuthSessionStatus'
import GuestLayout from '../../components/Layouts/GuestLayout'
import InputError from '../../components/InputError'
import { useAuth } from '../../hooks/auth'
import { useStateContext } from '../../context/ContextProvider'
import Wrapper from '../../components/Wrapper'
import { isEqual } from 'lodash'

const Login = () => {

    const { t } = useTranslation();
    const { setChoosesite } = useStateContext()

    const { login } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/choosesite',
    })

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [shouldRemember, setShouldRemember] = useState(false)
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)
    const [type, setType] = useState('password')

    const submitForm = async event => {
        event.preventDefault()
        setChoosesite(false)
        localStorage.removeItem('site')

        login({
            email,
            password,
            remember: shouldRemember,
            setErrors,
            setStatus,
        })
        let filter = [{}]
        localStorage.setItem('filters', JSON.stringify(filter))
    }

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
    }, [])

    return (
        <GuestLayout>

            <AuthCard>
                {/* Session Status */}
                <AuthSessionStatus className="mb-4" status={status} />
                <Wrapper>
                    <form onSubmit={submitForm} noValidate>
                        <div className='min-w-[400px] max-[500px]:min-w-[300px]'>
                            <div className='w-full'>
                                <p className='text-[#718096] text-[18px] mb-3'>{t('email')}</p>
                                <TextField
                                    id="input-with-icon-textfield"
                                    placeholder={t('enter_your_email')}
                                    name='email'
                                    className='w-full'
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa-solid fa-envelope"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <InputError messages={errors.email} className="mt-2" />
                            </div>

                            <div className='w-full pt-5'>
                                <p className='text-[#718096] text-[18px] mb-3'>{t('password')}</p>
                                <TextField
                                    id="input-with-icon-textfieldtwo"
                                    name='password'
                                    placeholder={'••••••'}
                                    className='w-full'
                                    type={type}
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    required
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa-solid fa-lock"></i>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div className='cursor-pointer' onClick={() => setType(type == 'text' ? 'password' : 'text')}><i className={`fa-regular ${isEqual(type, 'password') ? 'fa-eye' : 'fa-eye-slash'}`}></i></div>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <InputError messages={errors.password} className="mt-2" />
                            </div>

                            <div className='flex justify-end w-full py-5'>
                                <Link to="/forgot-password" className="text-[14px] text-[#BA8431]">
                                    {t('forgot_password')}
                                </Link>
                            </div>

                            <div className="flex items-center w-full">
                                <button className="w-full rounded-md bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC] text-white uppercase py-3 font-[700] text-[16px]" type='submit'>{t("login")}</button>
                            </div>
                        </div>
                    </form>
                </Wrapper>
            </AuthCard>
        </GuestLayout>
    )
}

export default Login
