import React, { useState, useEffect } from 'react'

import { Modal, Box, TextField, Autocomplete } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { handleInteger, handleZero, deleteZero, handleNumber } from '../../helpers/helper'

const style = {
    minWidth: "360px",
    maxWidth: "540px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}


const AddSalesOrderItem = ({ open, handleOpen, salesOrderItems, setSalesOrderItems, disabled, setDisabled }) => {

    const { t } = useTranslation()
    const { config, currencySymbol } = useStateContext()

    /* state */
    const [items, setItems] = useState([])
    const [count, setCount] = useState(10000)
    const [selectedItem, setSelectedItem] = useState([])

    /* form */
    const [no, setNo] = useState('')
    const [noName, setNoName] = useState('')
    const [description, setDescription] = useState(' ')
    const [qty, setQty] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)

    /* useefect */
    useEffect(() => {
        const total = qty * unitPrice
        setTotalCost(total)
    }, [qty, unitPrice])

    useEffect(() => {
        if (open) {
            getItems(salesOrderItems.map(({ item_no }) => item_no))
        }
    }, [salesOrderItems])

    useEffect(() => {
        if (open) {
            if (no) {
                getItem(no)
            } else {
                setDescription('')
                setUnitPrice(0)
                setDisabled(true)
            }
        }
    }, [no])

    useEffect(() => {
        if (open) {
            getItems(salesOrderItems.map(({ item_no }) => item_no))
        }
    }, [open])

    useEffect(() => {
        if (!Array.isArray(selectedItem)) {
            setDescription(selectedItem?.description)
            setUnitPrice(selectedItem?.unit_price)
        }
    }, [selectedItem])


    /* getters */
    const getItems = async (without = []) => {
        await axios.get(`/api/items?blocked_for_sales=0&blocked=0&company_id=${localStorage.getItem('company_id')}`, config)
            .then(res => {
                const items = res.data
                const filteredItems = items.filter(item => {
                    return !without.includes(item['item_no'])
                })
                setItems(filteredItems)
            })
    }

    const getItem = async (id) => {
        await axios.get(`/api/items/${id}?with=unit_of_measure`, config)
            .then(res => {
                const item = res.data
                setSelectedItem(item)
                setDisabled(false)
            })
    }

    /* handlers */
    const closeAndCleanUp = () => {
        handleOpen()
        setNo('')
        setDescription(' ')
        setQty(0)
        setUnitPrice(0)
        setTotalCost(0)
        setDisabled(true)
    }

    const createItem = async (e) => {
        e.preventDefault();

        let new_item = {
            "id": count,
            "item_no": selectedItem.item_no,
            "description": description,
            "qty_ordered": qty,
            "qty_to_ship": qty,
            "qty_shipped": 0,
            "qty_loaded": 0,
            "unit_price": unitPrice,
            "total_cost": totalCost,
            "color": selectedItem.color,
            "unit_id": selectedItem.id,
            "unit_of_measure_code": selectedItem?.unit_of_measure?.code
        }

        setSalesOrderItems((prev) => [...prev, new_item])
        setCount(prev => prev + 1)

        closeAndCleanUp()
    }

    const options = items?.map(option => ({ label: option.item_no, id: option.id }))

    return (
        <Modal open={open} onClose={closeAndCleanUp}>
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{t('addsalesOrderitems')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={closeAndCleanUp}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start pt-1'>
                            <div className='pb-4 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('no')}</p>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '0 !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    onChange={(a, b) => { setNo(b?.id || '') }}
                                    renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                />
                            </div>

                            <div className='pb-4 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('description')}</p>
                                <TextField
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    type="text"
                                    name="description"
                                    value={description}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    disabled
                                />
                            </div>

                            <div className='flex gap-5 w-full pb-4'>
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('qty')}</p>
                                    <TextField
                                        sx={{
                                            width: '100%',
                                            background: '#FCFCFC',
                                            '.MuiOutlinedInput-input': {
                                                padding: '9px !important'
                                            },
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #EEEFF2 !important'
                                            }
                                        }}
                                        type="text"
                                        name="qty"
                                        value={qty}
                                        onChange={(e) => handleInteger({
                                            event: e,
                                            setter: setQty
                                        })}
                                        onBlur={e => handleZero({
                                            event: e,
                                            setter: setQty
                                        })}
                                        onFocus={e => deleteZero({
                                            event: e,
                                            setter: setQty,
                                            value: qty
                                        })}
                                        disabled={disabled}
                                    />
                                </div>
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('unit_price') + ` (${currencySymbol})`}</p>
                                    <TextField
                                        sx={{
                                            width: '100%',
                                            background: '#FCFCFC',
                                            '.MuiOutlinedInput-input': {
                                                padding: '9px !important'
                                            },
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #EEEFF2 !important'
                                            }
                                        }}
                                        type="text"
                                        name="unitPrice"
                                        value={unitPrice}
                                        onChange={(e) => handleNumber({
                                            event: e,
                                            setter: setUnitPrice,
                                            precision: 2
                                        })}
                                        onBlur={e => handleZero({
                                            event: e,
                                            setter: setUnitPrice
                                        })}
                                        onFocus={e => deleteZero({
                                            event: e,
                                            setter: setUnitPrice,
                                            value: unitPrice
                                        })}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>

                            <div className='pb-5 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('total_cost')}</p>
                                <TextField
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    type="text"
                                    name="total_cost"
                                    value={totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    onChange={(e) => { setTotalCost(e.target.value) }}
                                    disabled
                                />
                            </div>

                        </form>
                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={handleOpen} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                            <button type="button" onClick={createItem} disabled={disabled} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC] capitalize'>
                                {t('add')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddSalesOrderItem
