import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import { Tooltip } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TablePagination from "@mui/material/TablePagination";
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useState } from 'react'
import { visuallyHidden } from '@mui/utils';
import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'
import { toLower } from 'lodash'

function Row(props) {
    const { row, user } = props;
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px', position: 'relative', fontWeight: '500' }}>{row.sales_order.so_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px', fontWeight: '500' }}>{row.purchase_order.po_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.sales_order.requested_delivery_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        <Status status_key={row.bt_status} />
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 5px' }}>
                    {
                        <TodaysSalesOrderAction id={row.id} status={row.bt_status_id} user={user} row={row} />
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '50%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_ordered')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_to_ship')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_shipped')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.sales_order.items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_to_ship}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_shipped}</TableCell>
                                        </TableRow>
                                    ))}
                                    {/*  {row.purchase_order.items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.vendor_count}</TableCell>
                                        </TableRow>
                                    ))} */}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Sales Order No. cell */}
                <TableCell
                    sortDirection={orderBy === 'so_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'so_number'}
                        direction={orderBy === 'so_number' ? order : 'asc'}
                        onClick={createSortHandler('so_number')}>
                        {t('sales_order_no')}
                        {orderBy === 'so_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Customer Name cell */}
                <TableCell
                    sortDirection={orderBy === 'po_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'po_number'}
                        direction={orderBy === 'po_number' ? order : 'asc'}
                        onClick={createSortHandler('po_number')}>
                        {t('purchase_order_no')}
                        {orderBy === 'po_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Requested Delivery Date cell */}
                <TableCell
                    sortDirection={orderBy === 'requested_delivery_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_delivery_date'}
                        direction={orderBy === 'requested_delivery_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_delivery_date')}>
                        {t('requested_delivery_date')}
                        {orderBy === 'requested_delivery_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Status cell */}
                <TableCell
                    sortDirection={orderBy === 'bt_status_id' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'bt_status_id'}
                        direction={orderBy === 'bt_status_id' ? order : 'asc'}
                        onClick={createSortHandler('bt_status_id')}>
                        {t('status')}
                        {orderBy === 'bt_status_id' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: '#88909C !important', fontWeight: '400' }}>{t('actions')}</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items, user }) {

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    return (
        <>

            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="10%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} user={user} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

const Status = ({ status_key }) => {

    const getStatusColorStylings = () => {
        switch(toLower(status_key)) {
            case "open":
            case "reopened":
                return "bg-[#D2ECFE] text-[#0073C1]"
            case "released":
                return "bg-[#FFD48F] text-[#9D6000]"
            case "assigned_to_driver":
                return "bg-[#FFE4B9] text-[#804E00]"
            case "in_transit":
                return "bg-[#D5EDFB] text-[#008A93]"
            case "shipped":
                return "bg-[#019738] text-[#ffffff]"
            case "completed":
                return "bg-[#BEFFD6] text-[#017C40]"
            case "invoiced":
                return "bg-[#E7F7EF] text-[#0CAF60]"
            default:
                return "bg-[#FFF0F0] text-[#FD6A6A]"
        }
    }

    return (
        <div className={"w-full rounded-md p-1 text-center font-[500] capitalize " + getStatusColorStylings()}>{t(status_key)}</div>
    )
}

const TodaysSalesOrderAction = ({ id, status, user }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const constructPodUrl = ({
        id,
        stream = 'view',
        party = 'supplier'
    }) => {
        return process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${id}/proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`
    }

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    return (
        <>
            <div className='flex gap-1'>
                <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={constructPodUrl({id: id})} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>

                {
                    (
                        (
                            ['master_admin', 'office_manager'].includes(user?.role)
                            || userHasPermissions(['daily-board-update'])
                        )
                        && (
                            status === 1
                            || status === 3
                        )
                    ) ? (
                        <Tooltip disableInteractive title={t('update')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <button onClick={() => navigate(`${id}`, { state: { prevPathname: location.pathname } })}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></button>
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <button onClick={() => navigate(`${id}`, { state: { prevPathname: location.pathname } })}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></button>
                            </div>
                        </Tooltip>
                    )
                }

            </div>
        </>
    )
}

export const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: '#F00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                        date1 > date2 ? <p style={{ color: '#008E00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                            <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p>
            }
        </div>
    )
}
