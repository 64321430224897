import React, {useEffect, useState} from 'react'

import {useTranslation} from "react-i18next"
import {useParams, useSearchParams} from 'react-router-dom'

import AssignedShipments from '../../components/driver/AssignedShipments'
import AppLayout from '../../components/Layouts/AppLayout'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import Loading from '../../components/Loading'
import { handleAxiosError, isValid } from '../../helpers/helper'
import Swal from 'sweetalert2'
import { useAuth } from '../../hooks/auth'

const AvailableLoadsExternal = () => {

    const { user } = useAuth({ middleware: 'guest' })
    const { t } = useTranslation()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const { config, pusher, choosesite } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)

    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])

    useEffect(() => {
        searchParams.delete('code')
        setSearchParams(searchParams)

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelShipmentDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)

            channelShipmentDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                setShipments((prev) => {
                    const target = prev.find((t) => t.id == data.id)
                    if(target) {
                        Swal.fire({
                            icon: "info",
                            iconColor: '#a8d5f1',
                            customClass: 'info',
                            timer: 3000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                            text: data.message_localized ?? data.message
                        })

                        return prev.filter((s) => s.id !== target.id)
                    } else {
                        return prev
                    }
                })
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        switch(user?.role) {
            case "tpl_driver":
                getTPLSalesOrder()
                break
            case "service_provider":
                getBrokerSalesOrder()
                break
        }
    }, [user])

    const getTPLSalesOrder = async () => {
        setIsLoading(true)
        await axios.get(`/api/3pl/3pl-order?tpl_access_code=${params.id}`, config)
            .then(res => {
                const data = res.data
                setShipments([data])
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getBrokerSalesOrder = async () => {
        setIsLoading(true)
        await axios.get(`/api/broker-sales/broker-sales-order?service_provider_access_code=${params.id}`, config)
            .then(res => {
                const data = res.data
                setShipments([data])
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
        {isLoading ? <Loading /> : null}
        <AppLayout>
            <div className='flex flex-col justify-between'>
                <div className='p-5 w-full'>
                    <div className='bg-white mb-2 rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b'>
                            <div className='flex justify-start gap-4 items-center'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('shipments')}</p>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <AssignedShipments shipments={shipments ?? []} />
                        </div>
                    </div>
                </div>

                {/* <div className='p-5 w-full'>
                    <div className='bg-white mb-2 rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b'>
                            <div className='flex justify-start gap-4 items-center'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('collections')}</p>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <AssignedCollections collections={collections ?? []} />
                        </div>
                    </div>
                </div> */}
            </div>
        </AppLayout>
        </>
    )

}

export default AvailableLoadsExternal
