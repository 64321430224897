import React, { useState, useEffect } from 'react'

import { Card, CardContent, Typography, Tooltip, Stack } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import update from 'immutability-helper'

import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import DailyBoardTableSales from '../../components/DailyBoardTableSales'
import { Link } from 'react-router-dom'
import Create3PLSalesOrderIcon from '../../components/icons/Create3PLSalesOrderIcon'
import CreatePickupIcon from '../../components/icons/CreatePickupIcon'
import { useAuth } from '../../hooks/auth'
import { areAllValid, isValid } from '../../helpers/helper'
import Navigation from '../../components/sales_purchase_orders/Navigation'
import InventoryStatusScrollableRow from '../../components/admin_dashboard/InventoryStatusScrollableRow'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { includes, isNull } from 'lodash'
import CreateBrokeredSalesOrderIcon from '../../components/icons/CreateBrokeredSalesOrderIcon'
import CreateSalesOrder from '../../components/sales_purchase_orders/CreateSalesOrder'

const SalesOrder = () => {

    const { user } = useAuth({ middleware: 'guest ' })

    const { t } = useTranslation()
    const { choosesite, setChoosesite, company_id, config, pusher } = useStateContext()

    const [open, setOpen] = useState(false)
    const [openSalesPickup, setOpenSalesPickup] = useState(false)
    const [open3PL, setOpen3PL] = useState(false)
    const [openCreateBrokeredSalesOrder, setOpenCreateBrokeredSalesOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [random, setRandom] = useState(null)

    const [salesOrderStatuses, setSalesOrderStatuses] = useState([])
    const [salesOrderStatusesFuture, setSalesOrderStatusesFuture] = useState([])
    const [salesOrderDates, setSalesOrderDates] = useState([])
    const [salesOrderDatesFuture, setSalesOrderDatesFuture] = useState([])

    /* orders */
    const [orders, setOrders] = useState([])
    const [ordersFuture, setOrdersFuture] = useState([])

    /* filters */
    const [type, setType] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_type || ''
        } else {
            return ''
        }
    })
    const [typeFuture, setTypeFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_sales_order_type || ''
        } else {
            return ''
        }
    })
    const [searchStatusFuture, setSearchStatusFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_sales_order_status || ''
        } else {
            return ''
        }
    })
    const [searchDateFuture, setSearchDateFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_sales_order_requested_delivery_date || ''
        } else {
            return ''
        }
    })
    const [searchStatus, setSearchStatus] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_status || ''
        } else {
            return ''
        }
    })
    const [searchDate, setSearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_requested_delivery_date || ''
        } else {
            return ''
        }
    })

    // Misc.

    const [inventoryItems, setInventoryItems] = useState([])
    const [isLoadingInventoryItems, setIsLoadingInventoryItems] = useState(true)
    const [isUpdatingInventoryItems, setIsUpdatingInventoryItems] = useState(false)
    const [isInventoryOpen, setIsInventoryOpen] = useState(true)
    const [shownItemType, setShownItemType] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.sales_order_inventory_item_type || 'all'
        } else {
            return 'all'
        }
    })

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if(isValid(choosesite)) {
            const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
            const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelSalesOrderStatusChanged = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-site-${choosesite}`)
            const channelPurchaseOrderStatusChanged = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-site-${choosesite}`)
            const channelProductionEntryCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-productionentry-created-site-${choosesite}`)
            const channelProductionEntryUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-productionentry-updated-site-${choosesite}`)

            channeldelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                //usporediti datume da vidimo u koji order ide
                setRandom(Math.random())
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelSalesOrderStatusChanged.bind(`${localStorage.getItem('client_id')}-salesorder-status-changed`, data => {
                /* If an Order is Shipped or Undone */
                if(
                    Number(data.status_id) == 9
                    || (Number(data.prev_status_id) == 9 && Number(data.status_id) < Number(data.prev_status_id))
                ) {
                    getInventoryItems(choosesite, true)
                }
            })

            channelPurchaseOrderStatusChanged.bind(`${localStorage.getItem('client_id')}-purchaseorder-status-changed`, data => {
                /* If an Order is Completed or Undone */
                if(
                    Number(data.status_id) == 10
                    || (Number(data.prev_status_id) == 10 && Number(data.status_id) < Number(data.prev_status_id))
                ) {
                    getInventoryItems(choosesite, true)
                }
            })

            channelProductionEntryCreated.bind(`${localStorage.getItem('client_id')}-productionentry-created-event`, data => {
                getInventoryItems(choosesite, true)
            })
            channelProductionEntryUpdated.bind(`${localStorage.getItem('client_id')}-productionentry-updated-event`, data => {
                getInventoryItems(choosesite, true)
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if(isValid(company_id)) {
            const channelInventoryItemCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventoryitem-created-company-${company_id}`)
            const channelInventoryItemUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventoryitem-updated-company-${company_id}`)

            channelInventoryItemUpdate.bind(`${localStorage.getItem('client_id')}-inventoryitem-updated-event`, data => {
                getInventoryItems(null, true)
            })

            channelInventoryItemCreate.bind(`${localStorage.getItem('client_id')}-inventoryitem-created-event`, data => {
                getInventoryItems(null, true)
            })
        }

        return (() => {
            if(isValid(company_id)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventoryitem-updated-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventoryitem-created-company-${company_id}`)
            }
        })
    }, [company_id])

    useEffect(() => {
        if(isValid(choosesite)) {
            getInventoryItems(choosesite)
        }
    }, [choosesite])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrders()
        }
    }, [choosesite, searchDate, searchStatus, type, random])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrdersFuture()
        }
    }, [choosesite, searchDateFuture, searchStatusFuture, typeFuture, random])

    const getOrders = async () => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=sales&site_id=${choosesite}&status_id=${searchStatus}&requested_delivery_date=${searchDate}&type=${type}&pending=true`, config)
            .then(res => {
                const orders = res.data?.data.filter((i) => i.so_status_id < 9);
                getSalesOrderStatuses(orders)
                getSalesOrderDates(orders)
                setOrders(orders.filter((item) => new Date(dayjs(item.requested_delivery_date)) <= new Date(dayjs())))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getOrdersFuture = async () => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=sales&site_id=${choosesite}&status_id=${searchStatusFuture}&requested_delivery_date=${searchDateFuture}&type=${typeFuture}&pending=true`, config)
            .then(res => {
                const orders = res.data?.data.filter((i) => i.so_status_id < 9);
                getSalesOrderStatusesFuture(orders)
                getSalesOrderDatesFuture(orders)
                setOrdersFuture(orders.filter((item) => new Date(dayjs(item.requested_delivery_date)) > new Date(dayjs())))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getSalesOrderStatuses = (salesOrders) => {
        let pastPresentSalesOrders = salesOrders.filter((item) => new Date(dayjs(item.requested_delivery_date)) <= new Date(dayjs()));

        let statuses = [];

        pastPresentSalesOrders.forEach(order => {
            let newStatus = {
                id: order.so_status_id,
                name: order.so_status_name,
            };

            let newStatusExists = statuses.some(function (status) {
                return status.id === newStatus.id
            });

            if (!newStatusExists) {
                statuses.push(newStatus);
            }
        });

        statuses.sort((a, b) => {
            return a.id - b.id
        });

        setSalesOrderStatuses(statuses);
    }

    const getSalesOrderStatusesFuture = (salesOrders) => {
        let futureSalesOrders = salesOrders.filter((item) => new Date(dayjs(item.requested_delivery_date)) > new Date(dayjs()));

        let futureStatuses = [];

        futureSalesOrders.forEach(order => {
            let newFutureStatus = {
                id: order.so_status_id,
                name: order.so_status_name,
            };

            let newFutureStatusExists = futureStatuses.some(function (status) {
                return status.id === newFutureStatus.id
            });

            if (!newFutureStatusExists) {
                futureStatuses.push(newFutureStatus);
            }
        });

        futureStatuses.sort((a, b) => {
            return a.id - b.id
        });

        setSalesOrderStatusesFuture(futureStatuses);
    }

    const getSalesOrderDates = (salesOrders) => {
        let pastPresentSalesOrders = salesOrders.filter((item) => new Date(dayjs(item.requested_delivery_date)) <= new Date(dayjs()));

        let dates = [];

        pastPresentSalesOrders.forEach(order => {
            let newDate = order.requested_delivery_date;

            let newDateExists = dates.includes(newDate);

            if (!newDateExists) {
                dates.push(newDate)
            }
        });

        dates.sort();

        setSalesOrderDates(dates);
    }

    const getSalesOrderDatesFuture = (salesOrders) => {
        let futureSalesOrders = salesOrders.filter((item) => new Date(dayjs(item.requested_delivery_date)) > new Date(dayjs()));

        let futureDates = [];

        futureSalesOrders.forEach(order => {
            let newFutureDate = order.requested_delivery_date;

            let newFutureDateExists = futureDates.includes(newFutureDate);

            if (!newFutureDateExists) {
                futureDates.push(newFutureDate);
            }
        });

        futureDates.sort();

        setSalesOrderDatesFuture(futureDates);
    }

    const getInventoryItems = async (siteId = null, dueToPusherEvent = false) => {
        if(dueToPusherEvent) {
            setIsUpdatingInventoryItems(true)
        } else {
            setIsLoadingInventoryItems(true)
        }

        await axios.get(`/api/inventory-status?site=${isNull(siteId) ? localStorage.getItem('site') : siteId}`, config)
            .then(res => {
                const data = res.data
                setInventoryItems(data)
            })
            .catch(({response}) => {
                console.log(response)
            })
            .finally(() => {
                if(dueToPusherEvent) {
                    setIsUpdatingInventoryItems(false)
                } else {
                    setIsLoadingInventoryItems(false)
                }
            })
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleOpenSalesPickup = () => {
        setOpenSalesPickup(!openSalesPickup)
    }

    const handleOpen3PL = () => {
        setOpen3PL(!open3PL)
    }

    const handleOpenCreateBrokeredSalesOrder = () => {
        setOpenCreateBrokeredSalesOrder(!openCreateBrokeredSalesOrder)
    }

    const handleClearStatus = () => {
        setSearchStatusFuture('')

        getSalesOrderDatesFuture(ordersFuture)
    }

    const handleClearStatus1 = () => {
        setSearchStatus('')

        getSalesOrderDates(orders)
    }

    const handleClearDate = () => {
        setSearchDateFuture('')

        getSalesOrderStatusesFuture(ordersFuture)
    }

    const handleClearDate1 = () => {
        setSearchDate('')

        getSalesOrderStatuses(orders)
    }

    const handleClearType = () => {
        setType('')
    }

    const handleClearTypeFuture = () => {
        setTypeFuture('')
    }

    const createFilterArray = (status, requestedDeliveryDate, futureStatus, futureRequestedDeliveryDate, type, futureType, shownItemType) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].sales_order_status = status
        filters[0].sales_order_requested_delivery_date = requestedDeliveryDate
        filters[0].sales_order_type = type
        filters[0].future_sales_order_status = futureStatus
        filters[0].future_sales_order_requested_delivery_date = futureRequestedDeliveryDate
        filters[0].future_sales_order_type = futureType
        filters[0].sales_order_inventory_item_type = shownItemType

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchStatus, searchDate, searchStatusFuture, searchDateFuture, type, typeFuture, shownItemType)
    }, [searchStatus, searchDate, searchStatusFuture, searchDateFuture, type, typeFuture, shownItemType])

    return (
        <>

            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <Card className='w-auto m-6 mt-0'>
                    <CardContent sx={{paddingBottom: (inventoryItems.length <= 0 || !isInventoryOpen) ? '16px !important' : '24px !important'}}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={((isLoadingInventoryItems || inventoryItems.length > 0) && isInventoryOpen) ? '16px' : '0px'}>
                            <Stack spacing={1} direction='row' alignItems='center'>
                                <GridViewOutlinedIcon fontSize='16px' sx={{color: '#797979'}} />
                                <Typography fontWeight='600' fontSize='16px' lineHeight='24px' letterSpacing='0.3px' component="div">
                                    {t('inventory')}
                                </Typography>

                                {!isLoadingInventoryItems && inventoryItems.length <= 0 ? (
                                    <Typography fontWeight='300' fontSize='16px' lineHeight='24px' letterSpacing='0.3px' component="div">
                                        <em>{t('no_inventory_items_defined')}</em>
                                    </Typography>
                                ) : null}

                                {((!isLoadingInventoryItems && inventoryItems.length <= 0) && includes(['master_admin', 'office_manager'], user?.role)) ? (
                                    <Tooltip disableInteractive title={t('add_new_inventory_item')} placement='right' arrow>
                                        <Link to={'/items'}>
                                            <IconButton disabled={isLoading || isUpdatingInventoryItems} size='small' aria-label='redirect to items view'>
                                                <i className={`fa-solid fa-plus`}></i>
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                ) : null}
                            </Stack>
                            {inventoryItems.length > 0 ? (
                                <IconButton onClick={() => setIsInventoryOpen(!isInventoryOpen)} size='small' aria-label='collapse inventory items'>
                                    <i className={`transition duration-150 ease-in-out fa-solid fa-angle-up ${isInventoryOpen ? 'rotate-180' : 'rotate-0'}`}></i>
                                </IconButton>
                            ) : (
                                ''
                            )}
                        </Stack>

                        {isInventoryOpen ? (
                            <div
                                className={`${!isLoadingInventoryItems && inventoryItems.length <= 0 ? 'hidden' : 'flex'} w-full items-center justify-center px-1 rounded-md bg-[#ededf1] mb-[7px]`}
                                style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }}
                            >
                                <button onClick={() => setShownItemType('pallet')}  className='w-full'>
                                    <div
                                        className={`w-full text-center m-1 ml-0 ${shownItemType == 'pallet' ? 'bg-white rounded-sm' : 'border-r-2'}`}
                                        style={{
                                            border: shownItemType == 'pallet' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                            boxShadow: shownItemType == 'pallet' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                        }}
                                    >
                                        <p className='p-1.5'>{t('pallets')}</p>
                                    </div>
                                </button>

                                <button onClick={() => setShownItemType('lumber')} className='w-full'>
                                    <div
                                        className={`w-full text-center m-1 ml-0 mr-0 ${shownItemType == 'lumber' ? 'bg-white rounded-sm' : ''}`}
                                        style={{
                                            border: shownItemType == 'lumber' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                            boxShadow: shownItemType == 'lumber' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                        }}
                                    >
                                        <p className='p-1.5'>{t('lumber')}</p>
                                    </div>
                                </button>

                                <button onClick={() => setShownItemType('component')} className='w-full'>
                                    <div
                                        className={`w-full text-center m-1 ml-0 mr-0 ${shownItemType == 'component' ? 'bg-white rounded-sm' : ''}`}
                                        style={{
                                            border: shownItemType == 'component' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                            boxShadow: shownItemType == 'component' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                        }}
                                    >
                                        <p className='p-1.5'>{t('components')}</p>
                                    </div>
                                </button>

                                <button onClick={() => setShownItemType('all')} className='w-full'>
                                    <div
                                        className={`w-full text-center m-1 ml-0 mr-0 ${shownItemType == 'all' ? 'bg-white rounded-sm' : ''}`}
                                        style={{
                                            border: shownItemType == 'all' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                            boxShadow: shownItemType == 'all' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                        }}
                                    >
                                        <p className='p-1.5'>{t('all_inventory')}</p>
                                    </div>
                                </button>
                            </div>
                        ) : null}

                        {inventoryItems.length > 0 ? (
                            <InventoryStatusScrollableRow
                                data={inventoryItems.filter((item) => {
                                    if(shownItemType === 'all') {
                                        return item
                                    } else {
                                        return item.item?.item_type_key == shownItemType
                                    }
                                })}
                                isLoading={isLoadingInventoryItems}
                                isUpdating={!isLoadingInventoryItems && isUpdatingInventoryItems}
                                isOpen={isInventoryOpen}
                                overflow={true}
                            />
                        ) : null}
                    </CardContent>
                </Card>

                <Navigation route='sales-order' user={user} />

                <div className='p-6 flex w-full items-start justify-between gap-5 max-[1300px]:flex-col'>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ transform: 'rotate(45deg)', fontSize: '16px', color: '#336195' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('todays_sales_orders')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <>
                                        <Tooltip disableInteractive title={t('create_new_so')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus text-[#333333]"></i></AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_sales_pickup')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpenSalesPickup}>
                                                    <CreatePickupIcon type='cpick' />
                                                </AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_3pl')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen3PL}>
                                                    <Create3PLSalesOrderIcon />
                                                </AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_brokered_order')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpenCreateBrokeredSalesOrder}>
                                                    <CreateBrokeredSalesOrderIcon />
                                                </AddButton>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                        <Select value={searchStatus} onChange={e => setSearchStatus(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatus ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatus ? (<IconButton sx={{ visibility: searchStatus ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                salesOrderStatuses.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    salesOrderStatuses.map((status) => (
                                                        <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select value={searchDate} onChange={e => setSearchDate(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                salesOrderDates.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    salesOrderDates.map((date) => (
                                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_type')}</InputLabel>
                                        <Select
                                            value={type}
                                            onChange={e => setType(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: type ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={type ? (<IconButton sx={{ visibility: type ? "visible" : "hidden", padding: '0' }} onClick={handleClearType}><ClearIcon /></IconButton>) : false}
                                        >
                                            <MenuItem value={'SHIPMENT'}>{t('standard')}</MenuItem>
                                            <MenuItem className='pickup' value={'CUSTOMER PICKUP'}>{t('customer_pickup')}</MenuItem>
                                            {!['dispatcher'].includes(user?.role) ? (
                                                <MenuItem className='tpl' value={'3PL'}>{t('3PL')}</MenuItem>
                                            ) : (
                                                null
                                            )}
                                            <MenuItem className="brokered" value={'BROKERED'}>{t('brokered')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div>
                            <DailyBoardTableSales items={orders} user={user} setIsLoading={setIsLoading}/>
                        </div>
                    </div>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ transform: 'rotate(45deg)', fontSize: '16px', color: '#336195' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('future_sales_orders')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <>
                                        <Tooltip disableInteractive title={t('create_new_so')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus text-[#333333]"></i></AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_sales_pickup')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpenSalesPickup}>
                                                    <CreatePickupIcon type='cpick' />
                                                </AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_3pl')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen3PL}>
                                                    <Create3PLSalesOrderIcon />
                                                </AddButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip disableInteractive title={t('create_new_brokered_order')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpenCreateBrokeredSalesOrder}>
                                                    <CreateBrokeredSalesOrderIcon />
                                                </AddButton>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            value={searchStatusFuture}
                                            onChange={e => setSearchStatusFuture(e.target.value)}
                                            label="Search role"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatusFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatusFuture ? (<IconButton sx={{ visibility: searchStatusFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                salesOrderStatusesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    salesOrderStatusesFuture.map((status) => (
                                                        <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                        </Select>

                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select
                                            value={searchDateFuture}
                                            onChange={e => setSearchDateFuture(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDateFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDateFuture ? (<IconButton sx={{ visibility: searchDateFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                salesOrderDatesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    salesOrderDatesFuture.map((date) => (
                                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_type')}</InputLabel>
                                        <Select
                                            value={typeFuture}
                                            onChange={e => setTypeFuture(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: typeFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={typeFuture ? (<IconButton sx={{ visibility: typeFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearTypeFuture}><ClearIcon /></IconButton>) : false}
                                        >
                                            <MenuItem value={'SHIPMENT'}>{t('standard')}</MenuItem>
                                            <MenuItem className='pickup' value={'CUSTOMER PICKUP'}>{t('customer_pickup')}</MenuItem>
                                            {!['dispatcher'].includes(user?.role) ? (
                                                <MenuItem className='tpl' value={'3PL'}>{t('3PL')}</MenuItem>
                                            ) : (
                                                null
                                            )}
                                            <MenuItem className="brokered" value={'BROKERED'}>{t('brokered')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div>
                            <DailyBoardTableSales items={ordersFuture} user={user} setIsLoading={setIsLoading} />
                        </div>
                    </div>
                </div>

                <CreateSalesOrder open={open} handleOpen={handleOpen} setIsLoading={setIsLoading} type='SHIPMENT'/>
                <CreateSalesOrder open={openSalesPickup} handleOpen={handleOpenSalesPickup} setIsLoading={setIsLoading} type='CUSTOMER PICKUP'/>
                <CreateSalesOrder open={open3PL} handleOpen={handleOpen3PL} setIsLoading={setIsLoading} type='3PL'/>
                <CreateSalesOrder open={openCreateBrokeredSalesOrder} handleOpen={handleOpenCreateBrokeredSalesOrder} setIsLoading={setIsLoading} type='BROKERED'/>
            </AppLayout>

        </>
    )
}

export default SalesOrder

