import React, { useState, useEffect } from 'react'

import {  Modal, Box, TextField, Autocomplete } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import dayjs from 'dayjs'

const AddItem = ({ open, handleClose, items, setItems }) => {

    const { t } = useTranslation()
    const { config, currencySymbol } = useStateContext()

    const [itemsToChoose, setItemsToChoose] = useState([])
    const [item, setItem] = useState([])

    const [no, setNo] = useState('')
    const [description, setDescription] = useState(' ')
    const [qty, setQty] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [qty1, setQty1] = useState(0)
    const [count, setCount] = useState(0)
    const [disabled, setDisabled] = useState(true)

    const deleteZero = (e) => {
        if(qty === 0) {
            setQty('')
        }
    }
    const deleteZeroRest = (e) => {
        if(qty1 === 0) {
            setQty1('')
        }
    }
    const handleZero = (e) => {
        if(e.target.value === "") {
            setQty(0)
        }
    }
    const handleZeroRest = (e) => {
        if(e.target.value === "" ) {
            setQty1(0)
        }
    }
    const handleNumber = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') setQty1(0)
            else setQty1(e.target.value)
        }
    }
    const handleQty = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') setQty(0)
            else setQty(e.target.value)
        }
    }

    useEffect(() => {
        getItems(items.map(({item_no}) => item_no))
    }, [items])

    useEffect(() => {
        if(itemsToChoose.length < 1) {
            setDisabled(true)
        }
    }, [itemsToChoose])


    useEffect(() => {
        const total = qty * qty1
        setTotalCost(total)
    }, [qty, qty1])


    useEffect(() => {
        if(no) {
            getItem(no)
        } else {
            setDescription('')
            setQty1(0)
            setDisabled(true)
        }
    }, [no])

    useEffect(() => {
        if (!Array.isArray(item)) {
            setDescription(item?.description)
            setQty1(item?.unit_price)
            setDisabled(false)
        }
    }, [item])

    const getItems = async (without = []) => {
        await axios.get(`/api/items?blocked_for_sales=0&blocked=0&company_id=${localStorage.getItem('company_id')}`, config)
            .then(res => {
                const shipTo = res.data
                const filteredShipTo = shipTo.filter(item => {
                    return !without.includes(item['item_no']);
                });
                setItemsToChoose(filteredShipTo)
            })
    }

    const getItem = async (id) => {
        await axios.get(`/api/items/${id}`, config)
            .then(res => {
                const shipTo = res.data
                setItem(shipTo)
            })
    }

    const createItem = (e) => {
        e.preventDefault();

        let new_item = {
            "id": count,
            "item_id": item.id,
            "item_no": item.item_no,
            "description": description,
            "quantity": qty,
            "unit_price": qty1,
            "color": item.color,
            "total_cost": totalCost,
            "created_at": dayjs().format('YYYY-MM-DDTHH:mm:ss')
        }

        setItemsToChoose((prev) => [...prev, new_item])
        setItems((prev) => [...prev, new_item])
        setCount((prev) => prev + 1)

        closeAndCleanUp()
    }

    const closeAndCleanUp = () => {
        handleClose()

        setItem([])
        setNo('')
        setDescription(' ')
        setQty(0)
        setQty1(0)
        setTotalCost(0)
        setDisabled(true)
    }

    const options = itemsToChoose?.map(option => ({ label: option.item_no, id: option.id }))

    return (
        <Modal open={open} onClose={closeAndCleanUp}>
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{t('addsalesContractItems')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={closeAndCleanUp}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start pt-1'>
                            <div className='pb-4 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('no')}</p>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    onChange={(a, b) => setNo(b?.id || '') }
                                    renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                />
                            </div>

                            <div className='pb-4 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('description')}</p>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="description" value={description} onChange={(e) => { setDescription(e.target.value) }} disabled />
                            </div>

                            <div className='flex gap-5 w-full pb-4'>
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('qty')}</p>
                                    <TextField sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                        type="text" className='w-full' name="qty" value={qty} onChange={(e) => { handleQty(e) }} onBlur={e => handleZero(e)} onFocus={e => deleteZero(e)} />
                                </div>
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('unit_price') + ` (${currencySymbol})`}</p>
                                    <TextField sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                        type="text" className='w-full' name="unitPrice" value={qty1} onChange={(e) => { handleNumber(e) }} onBlur={e => handleZeroRest(e)} onFocus={e => deleteZeroRest(e)} disabled={disabled} />
                                </div>
                            </div>

                            <div className='pb-5 w-full'>
                                <p className="text-[14px] text-[#718096]">{t('total_cost')}</p>
                                <TextField sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                }} type="text" className='w-full' name="total_cost" value={totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => { setTotalCost(e.target.value) }} disabled />
                            </div>

                        </form>
                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={closeAndCleanUp} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                            <button type="button" onClick={createItem} disabled={disabled} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC] capitalize'>
                                {t('add')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddItem

const style = {
    minWidth: "360px",
    maxWidth: "540px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
