import AppLayout from '../components/Layouts/AppLayout'
import {useAuth} from '../hooks/auth'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { DEFAULT_ROUTES } from '../constants/constants'

const Dashboard = () => {

    const {user} = useAuth({middleware: 'guest'})
    const navigate = useNavigate()


    return (
        <AppLayout>
            <></>
        </AppLayout>
    )
}

export default Dashboard
