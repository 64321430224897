import React, { useState, useEffect } from 'react'

import {
    Box,
    Modal,
    Tooltip,
    InputLabel,
    TextField,
    Chip,
    ListItemIcon,
    Checkbox,
    ListItemText,
    IconButton,
    MenuItem,
    FormControl,
    Select
} from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { useDebounce } from "use-debounce"
import { includes, split } from 'lodash';

import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import axios from '../../lib/axios'
import style from '../../styles/style'
import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading'
import ServerTable from '../../components/ServerTable';



const Users = () => {
    const { t } = useTranslation()
    const { companies, config, sites, getSites, roles, pusher, permissions } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [users, setUsers] = useState([])

    /* state */
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_name || ""
        } else {
            return ""
        }
    })
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [role, setRole] = useState("")
    const [email, setEmail] = useState("")
    const [site, setSite] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [debouncedValue] = useDebounce(search, 500)
    const [random, setRandom] = useState(null)

    const [searchCompany, setSearchCompany] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_company || ""
        } else {
            return ""
        }
    })
    const [searchRole, setSearchRole] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_role || ""
        } else {
            return ""
        }
    })
    const [searchSite, setSearchSite] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_site || ""
        } else {
            return ""
        }
    })

    const [filteredPermissionOptions, setFilteredPermissionOptions] = useState([])
    const [chosenPermissions, setChosenPermissions] = useState([])
    const [openPermissions, setOpenPermissions] = useState(false)
    const areAllPermissionsChosen = filteredPermissionOptions.length > 0 && chosenPermissions.length === filteredPermissionOptions.length

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [model, setModel] = useState({
        actions: true,
        company_name: true,
        email: true,
        id: true,
        name: true,
        role_name: true,
        site_name: true
    })


    useEffect(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            setSearch(filter[0]?.user_name || '')
            setSearchCompany(filter[0]?.user_company || '')
            setSearchSite(filter[0]?.user_site || '')
            setSearchRole(filter[0]?.user_role || '')
        }
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=users_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-user-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-user-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-user-updated`)


        channeldelete.bind(`${localStorage.getItem('client_id')}-user-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-user-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-user-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-updated`)
        })
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createUser()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name, email, role, company, site]);

    useEffect(() => {
        setFilteredPermissionOptions(permissions.filter((permission) => includes(split(permission.visible_to, ','), role)))
    }, [permissions, role])

    const getUsers = async (name, role, company, site) => {
        setIsLoading(true)
        await axios.get(`/api/users?role=${role}&company_id=${company}&site_id=${site}&name=${name}&page=${page + 1}&page_size=${pageSize}`, config)
            .then(res => {
                const users = res.data.data
                const total = res.data.total
                setUsers(users)
                setTotal(total)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const handleChangeRole = (event) => {
        setSearchRole(event.target.value)
    }

    const handleClearRoleClick = () => {
        setSearchRole('')
    }

    const handleChangeCompany = (event) => {
        setSearchCompany(event.target.value);
        getSites(event.target.value)
    }

    const handleClearCompanyClick = () => {
        setSearchCompany('')
        getSites('')
    }

    const handleChangeSite = (event) => {
        setSearchSite(event.target.value)
    }

    const handleClearSiteClick = () => {
        setSearchSite('')
    }

    useEffect(() => {
        //hendlat da ukoliko postoji jedan od filtera, user se ne okine
        getUsers(search, searchRole, searchCompany, searchSite)
    }, [searchCompany, searchRole, searchSite, debouncedValue, page, pageSize, random])


    /* methods */
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setCompany("")
        setEmail("")
        setName("")
        setSite("")
        setRole("")
    }

    useEffect(() => {
        isOffice()
    }, [user])

    const isOffice = () => {
        if (user?.role === "office_manager") {
            setCompany(user?.company_id)
            getSites(user?.company_id)
        }
    }

    const createUser = async () => {
        setIsLoading(true)
        const formData = new FormData()

        formData.append('name', name)
        formData.append('email', email)
        formData.append('role', role)
        formData.append('company_id', company)
        formData.append('site_id', site)
        if(includes(['master_admin'], user?.role) && includes(['forklift_driver', 'driver', 'dispatcher', 'production_supervisor'], role)) {
            formData.append('permissions', chosenPermissions.map((permission) => permission.id))
        }

        await axios.post(`/api/users`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setName('')
            setEmail('')
            setCompany('')
            setRole('')
            setSite('')
            setIsLoading(false)
            handleClose()
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    const handleChangeCompanys = (event) => {
        setCompany(event.target.value)
        setSite('')
        getSites(event.target.value)
    }



    const handleClearRoles = () => {
        setRole('')
    }

    const handleClearCompanies = () => {
        setSite('')
        setCompany('')
    }

    const handleClearSites = () => {
        setSite('')
    }

    const handleChangePermissions = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = []

        value.forEach((permission) => {
            if (duplicateRemoved.findIndex((o) => o.id === permission.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === permission.id)
            } else {
                duplicateRemoved.push(permission)
            }
        })

        if (value[value.length - 1] === "all") {
            setChosenPermissions(chosenPermissions.length === filteredPermissionOptions.length ? [] : filteredPermissionOptions)
            return;
        }

        setChosenPermissions(duplicateRemoved)
    }

    const handleRemovePermission = (e, id) => {
        const shouldDelete = chosenPermissions.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = chosenPermissions.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setChosenPermissions(filtered)
            } else {
                setOpenPermissions(false)
                setChosenPermissions([])
            }
        }
    }

    const handleClearPermissions = () => {
        setChosenPermissions([])
    }


    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'role_name',
            headerName: t('role'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'company_name',
            headerName: t('company'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'site_name',
            headerName: t('site'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 1,
            cellClassName: 'padding-0',
            renderCell: (params) => <RenderDetailsButton params={params} setIsLoading={setIsLoading} />,
            minWidth: 200
        },

    ];

    const createFilterArray = (userName, userRole, userCompany, userSite) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].user_name = userName
        filters[0].user_role = userRole
        filters[0].user_company = userCompany
        filters[0].user_site = userSite

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(search, searchRole, searchCompany, searchSite)
    }, [search, searchRole, searchCompany, searchSite])

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md flex items-start flex-col'>
                        <div className='flex justify-between items-center p-5 border-b w-full'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('users_list')}</p>
                            <Tooltip disableInteractive title={t('create_user')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><span style={{ paddingLeft: '2px' }}><i className="fa-solid fa-plus flex justify-center"></i></span></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between items-end w-full border-b pb-5'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('search_by_name')} value={search} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearch(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('search_by_role')}</InputLabel>
                                    <Select
                                        value={searchRole}
                                        onChange={handleChangeRole}
                                        label="Search role"
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: searchRole ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={searchRole ? (<IconButton sx={{ visibility: searchRole ? "visible" : "hidden", padding: '0' }} onClick={handleClearRoleClick}><ClearIcon /></IconButton>) : false}
                                    >
                                        {
                                            roles.map((item, index) => <MenuItem key={index} value={item.slug}>{item.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            {
                                user?.role != 'master_admin' ?
                                    null
                                    :
                                    <div className='pt-5 pl-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_company')}</InputLabel>
                                            <Select
                                                value={searchCompany}
                                                onChange={handleChangeCompany}
                                                label="Search company"
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: searchCompany ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchCompany ? (<IconButton sx={{ visibility: searchCompany ? "visible" : "hidden", padding: '0' }} onClick={handleClearCompanyClick}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                            }
                            <div className='px-5 pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('search_by_site')}</InputLabel>
                                    <Select
                                        value={searchSite}
                                        onChange={handleChangeSite}
                                        label="Search site"
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: searchSite ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={searchSite ? (<IconButton sx={{ visibility: searchSite ? "visible" : "hidden", padding: '0' }} onClick={handleClearSiteClick}><ClearIcon /></IconButton>) : false}
                                    >
                                        {
                                            sites.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='pt-3 w-full'>
                            <ServerTable columns={columns} items={users} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='users' />
                        </div>
                    </div>
                </div>
            </AppLayout>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style, overflowX: 'hidden'}}>
                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_user')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-white h-auto min-h-full'>
                        <div className='bg-white p-6 w-full h-fit'>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>
                            </div>

                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('role')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={role} onChange={(e) => { setRole(e.target.value) }}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {roles.map((item, index) => <MenuItem key={index} value={item.slug}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                (user?.role !== "office_manager") ?
                                    (role !== "master_admin") ?

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Select value={company} onChange={handleChangeCompanys}
                                                        sx={{
                                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                        }}>
                                                        {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        : ''
                                    : ''
                            }

                            {
                                (role !== "master_admin" && role !== "office_manager" && role !== "salesperson" && role !== "client") ?
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('site')} *</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select value={site} onChange={e => { setSite(e.target.value) }}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}>
                                                    {(company !== "" && company) ?
                                                        sites.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                                        : ''}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div> : ''
                            }

                            {
                                (
                                    includes(['master_admin'], user?.role)
                                    && includes(['forklift_driver', 'driver', 'dispatcher', 'production_supervisor'], role)
                                ) ? (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('permissions')}</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    multiple
                                                    value={chosenPermissions}
                                                    open={openPermissions}
                                                    onOpen={() => setOpenPermissions(true)}
                                                    onClose={() => setOpenPermissions(false)}
                                                    onChange={handleChangePermissions}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((x) => (
                                                                <Chip
                                                                    key={`permission-${x.id}`}
                                                                    label={t(x.name)}
                                                                    size="small"
                                                                    onMouseDown={(e) => e.stopPropagation()}
                                                                    onDelete={(e) => handleRemovePermission(e, x.id)}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        },
                                                        ".MuiSelect-iconStandard": {
                                                            display: (chosenPermissions.length > 0) ? 'none !important' : ''
                                                        },
                                                        "&.Mui-focused .MuiIconButton-root": {
                                                            color: 'rgba(0,0,0,.42)'
                                                        }
                                                    }}
                                                    endAdornment={chosenPermissions ? (<IconButton sx={{ visibility: (chosenPermissions.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPermissions}><ClearIcon /></IconButton>) : false}
                                                >
                                                    {
                                                        filteredPermissionOptions?.length > 0 ?
                                                            <MenuItem value="all">
                                                                <ListItemIcon>
                                                                    <Checkbox checked={areAllPermissionsChosen} indeterminate={chosenPermissions.length > 0 && chosenPermissions.length < filteredPermissionOptions.length} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('select_all')} />
                                                            </MenuItem>
                                                            :
                                                            <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    }
                                                    {
                                                        filteredPermissionOptions.map((permission) =>
                                                            <MenuItem value={permission} key={permission.id}>
                                                                <Checkbox checked={chosenPermissions?.findIndex((i) => i.id === permission.id) >= 0} />
                                                                <ListItemText primary={t(permission.name)} />
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                ) : null
                            }

                        </div>
                        <div className='w-full flex justify-end px-6'>
                            <button type="button" onClick={createUser} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )

}

export default Users


const RenderDetailsButton = ({ params }) => {

    const { t } = useTranslation()
    const { config, setCompany_id, setChoosesite } = useStateContext()
    const { user, loginImpersonate } = useAuth({ middleware: 'guest' })
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const impersonate = async (id, role, company_id, site_id, name) => {
        await loginImpersonate({
            id: id,
            impersonator_id: user?.id,
            impersonatee_name: name,
            impersonatee_site_id: site_id,
            impersonatee_company_id: company_id,
            role: role,
            site_id: localStorage.getItem('site'),
            company_id: localStorage.getItem('company_id'),
            setErrors,
            setStatus,
        }).then((data) => {
            setCompany_id(company_id)
            setChoosesite(localStorage.getItem('site'))
        })
    }

    const deleteUser = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_user') + params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/users/${id}`, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                {
                    (user?.role === "master_admin" || user?.role === "office_manager") && (localStorage.getItem('impersonate') === null) && (params.row.role_slug !== "master_admin") ?
                        <Tooltip disableInteractive title={t('impersonate')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => impersonate(params.row.id, params.row.role_slug, params.row.company_id, params.row.site_id, params.row.name)}><i className="fa-solid fa-user"></i></span>
                            </div>
                        </Tooltip> : ''
                }
                <Tooltip disableInteractive title={t('change_password')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/users/change-password/${params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-key"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/users/${params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteUser(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
