import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import { Tooltip, FormControlLabel, Switch, Button, Popper } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import TablePagination from "@mui/material/TablePagination";
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useState } from 'react'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { visuallyHidden } from '@mui/utils';
import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'

function Row(props) {
    const { row, user, setIsLoading, hideCollapsible } = props;
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px', position: 'relative', fontWeight: '500' }}>
                    {row.so_number}
                </TableCell>
                <TableCell sx={{ padding: '8px 16px', fontStyle: 'italic' }}>{row.customer_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.requested_delivery_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        <Status status={row.so_status_id} status_name={row.so_status_name} />
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 5px' }}>
                    {
                        <TodaysSalesOrderAction
                            id={row.id}
                            brokerTransactionId={row?.broker_transaction_id}
                            accessCode={row?.service_provider_access_code}
                            statusId={row.so_status_id}
                            statusSlug={row.so_status_slug}
                            user={user}
                            setIsLoading={setIsLoading}
                        />
                    }
                </TableCell>
                {!hideCollapsible && (
                    <TableCell sx={{ padding: '8px 16px' }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '50%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_ordered')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('vendor_count')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.purchase_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.vendor_count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (

        <TableHead>

            <TableRow>
                {/* Sales Order No. cell */}
                <TableCell
                    sortDirection={orderBy === 'so_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'so_number'}
                        direction={orderBy === 'so_number' ? order : 'asc'}
                        onClick={createSortHandler('so_number')}>
                        {t('sales_order_no')}
                        {orderBy === 'so_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Customer Name cell */}
                <TableCell
                    sortDirection={orderBy === 'customer_name' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_name'}
                        direction={orderBy === 'customer_name' ? order : 'asc'}
                        onClick={createSortHandler('customer_name')}>
                        {t('customer')}
                        {orderBy === 'customer_name' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Requested Delivery Date cell */}
                <TableCell
                    sortDirection={orderBy === 'requested_delivery_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_delivery_date'}
                        direction={orderBy === 'requested_delivery_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_delivery_date')}>
                        {t('requested_delivery_date')}
                        {orderBy === 'requested_delivery_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Status cell */}
                <TableCell
                    sortDirection={orderBy === 'so_status' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'so_status'}
                        direction={orderBy === 'so_status' ? order : 'asc'}
                        onClick={createSortHandler('so_status')}>
                        {t('status')}
                        {orderBy === 'so_status' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: '#88909C !important', fontWeight: '400' }}>{t('actions')}</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items, user, hideCollapsible = false }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')



    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    return (
        <div>
            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <colgroup>
                            <col width="15%" />
                            <col width="15%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="10%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} user={user} hideCollapsible={hideCollapsible} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}

const Status = ({ status, status_name }) => {

    return (
        <>
            {
                status == 4 ?
                        <div className='w-full bg-[#FFF0B6] rounded-md p-1 text-center text-[#8A5001] font-[500]'>{status_name}</div>
                    :
                    status == 7 ?
                            <div className='w-full bg-[#FFE4B9] rounded-md p-1 text-center text-[#804E00] font-[500]'>{status_name}</div>
                        :
                        status == 1 ?
                            <div className='w-full bg-[#D2ECFE] rounded-md p-1 text-[#0073C1] text-center font-[500]'>{status_name}</div>
                            :
                            status == 2 ?
                                <div className='w-full bg-[#FFD48F] rounded-md p-1 text-[#9D6000] text-center font-[500]'>{status_name}</div>
                                :
                                status == 3 ?
                                    <div className='w-full bg-[#D2ECFE] rounded-md p-1 text-[#0073C1] text-center font-[500]'>{status_name}</div>
                                    :
                                    status == 5 ?
                                        <div className='w-full bg-[#FDFF8D] rounded-md p-1 text-[#BB7B00] text-center font-[500]'>{status_name}</div>
                                        :
                                        status == 6 ?
                                            <div className='w-full bg-[#FFED8D] rounded-md p-1 text-[#B16103] text-center font-[500]'>{status_name}</div>
                                            :
                                            status == 8 ?
                                                <div className='w-full bg-[#D5EDFB] rounded-md p-1 text-[#008A93] text-center font-[500]'>{status_name}</div>
                                                :
                                                status == 9 ?
                                                    <div className='w-full bg-[#BEFFD6] rounded-md p-1 text-[#017C40] text-center font-[500]'>{status_name}</div>
                                                    :
                                                    status == 10 ?
                                                        <div className='w-full bg-[#E8E8E8] rounded-md p-1 text-[#515151] text-center font-[500]'>{status_name}</div>
                                                        :
                                                        status == 11 ?
                                                            <div className='w-full bg-[#BEFFD6] rounded-md p-1 text-[#017C40] text-center font-[500]'>{status_name}</div>
                                                            :
                                                            status == 12 ?
                                                                <div className='w-full bg-[#FFD1D1] rounded-md p-1 text-[#B81100] text-center font-[500]'>{status_name}</div>
                                                                :
                                                                ''
            }
        </>
    )
}

const TodaysSalesOrderAction = ({
    id,
    brokerTransactionId = null,
    accessCode,
    statusId,
    statusSlug,
    user,
    setIsLoading
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const [open, setOpen] = useState(false)

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    return (
        <>
            <div className='flex gap-1'>
                <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/${['vendor_user'].includes(user?.role) ? ('broker-transactions/' + brokerTransactionId) : ('sales-orders/' + id)}/proof-of-delivery?stream=view&party=${['vendor_user'].includes(user?.role) ? 'supplier' : 'customer'}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>

                {
                    (
                        (
                            ['master_admin', 'office_manager'].includes(user?.role)
                            || userHasPermissions(['daily-board-update'])
                        )
                        && (
                            statusId === 1
                            || statusId === 3
                        )
                    ) ? (
                        <Tooltip disableInteractive title={t('update')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <button onClick={() => navigate(`${id}`, { state: { prevPathname: location.pathname } })}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></button>
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <button onClick={() => navigate(
                                    (['vendor_user'].includes(user?.role) && brokerTransactionId) ? `${brokerTransactionId}` : `${id}`,
                                    {
                                        state: {
                                            prevPathname: location.pathname
                                        }
                                    }
                                )}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></button>
                            </div>
                        </Tooltip>
                    )
                }

                {
                    (
                        ['vendor_user'].includes(user?.role)
                        && ['released', 'assigned_to_driver', 'in_transit'].includes(statusSlug)
                        && accessCode
                        && localStorage.getItem('impersonate') === null
                    ) ? (
                        <Tooltip disableInteractive title={t('view_as_driver')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <button onClick={() => navigate(`/broker-sales?code=${accessCode}`)}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-truck"></i></span></button>
                            </div>
                        </Tooltip>
                    ) : null
                }
            </div>
        </>
    )
}

export const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: '#F00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                        date1 > date2 ? <p style={{ color: '#008E00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                            <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p>
            }
        </div>
    )
}
