import React, { useEffect, useState } from 'react'

import { FormControl, InputLabel, Select, Box, Chip, IconButton, MenuItem, ListItemIcon, ListItemText, Checkbox, Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import Swal from 'sweetalert2'
import { Link, useSearchParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { includes } from 'lodash'
import dayjs from 'dayjs'

import AppLayout from '../../../../components/Layouts/AppLayout'
import Loading from '../../../../components/Loading'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../../../context/ContextProvider'
import { useAuth } from '../../../../hooks/auth'
import ServerTable from '../../../../components/ServerTable'
import { handleAxiosError, isValid } from '../../../../helpers/helper'
import axios from '../../../../lib/axios'

const ProductionOrders = () => {
    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest '})
    const [searchParams, setSearchParams] = useSearchParams()

    const [productionOrders, setProductionOrders] = useState([])
    const [startDates, setStartDates] = useState([])
    const [dueDates, setDueDates] = useState([])
    const [statuses, setStatuses] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [random, setRandom] = useState(null)

    const [openStatuses, setOpenStatuses] = useState(false)

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
            const channelProductionOrderCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)

            channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelProductionOrderCreate.bind(`${localStorage.getItem('client_id')}-productionorder-created-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            }
        })
    }, [choosesite])

    /* FILTERS */
    const [searchNo, setSearchNo] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_no || ""
        } else {
            return ""
        }
    })
    const [searchStatuses, setSearchStatuses] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_statuses || []
        } else {
            return []
        }
    })
    const [searchStatusIds, setSearchStatusIds] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_status_ids || []
        } else {
            return []
        }
    })
    const [searchStartDate, setSearchStartDate] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_start_date || ""
        } else {
            return ""
        }
    })
    const [searchDueDate, setSearchDueDate] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_due_date || ""
        } else {
            return ""
        }
    })

    const [debouncedSearchNo] = useDebounce(searchNo, 500)

    const [model, setModel] = useState({
        no: true,
        description: true,
        start_date: true,
        due_date: true,
        status: true,
        actions: true
    })

    const hasSelectedAllStatuses = statuses.length > 0 && searchStatuses.length === statuses.length;

    useEffect(() => {
        getTableView()
        getProductionOrderStatuses()
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            getProductionOrders()
        }
        createFilterArray(debouncedSearchNo, searchStatuses, searchStatusIds, searchStartDate, searchDueDate)
    }, [choosesite, searchStartDate, searchDueDate, searchStatuses, debouncedSearchNo, page, pageSize, random])

    const createFilterArray = (no, statuses, statusIds, startDate, dueDate) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_orders_no = no
        filters[0].production_orders_statuses = statuses
        filters[0].production_orders_status_ids = statusIds
        filters[0].production_orders_start_date = startDate
        filters[0].production_orders_due_date = dueDate

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=production_orders_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionOrders = async () => {
        setIsLoading(true)

        let startDate
        if (searchStartDate == '') {
            startDate = ''
        } else {
            startDate = dayjs(searchStartDate).format('YYYY-MM-DD')
        }

        let dueDate
        if(searchDueDate == '') {
            dueDate = ''
        } else {
            dueDate = dayjs(searchDueDate).format('YYYY-MM-DD')
        }

        await axios.get(`/api/list-orders?order_type=production&site_id=${choosesite}&no=${searchNo}&status_id=${searchStatusIds}&start_date=${startDate}&due_date=${dueDate}&rejected&page=${page + 1}&page_size=${pageSize}`, config)
            .then(res => {
                const data = res.data?.data
                const t = res.data?.total
                setProductionOrders(data)
                getStartDates(data)
                getDueDates(data)
                setTotal(t)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionOrderStatuses = async () => {
        await axios.get(`api/production-order-statuses`, config)
            .then(res => {
                const data = res.data
                setStatuses(data)
            })
    }

    const getStartDates = (productionOrders) => {
        let dates = [];

        productionOrders.forEach(order => {
            let newDate = order.start_date;

            let newDateExists = dates.includes(newDate);

            if (!newDateExists) {
                dates.push(newDate);
            }
        });

        dates.sort();

        if(!includes(dates, searchStartDate)) {
            setSearchStartDate('')
        }

        setStartDates(dates);
    }

    const getDueDates = (productionOrders) => {
        let dates = [];

        productionOrders.forEach(order => {
            let newDate = order.due_date;

            let newDateExists = dates.includes(newDate);

            if (!newDateExists) {
                dates.push(newDate);
            }
        });

        dates.sort();

        if(!includes(dates, searchDueDate)) {
            setSearchDueDate('')
        }

        setDueDates(dates);
    }

    const handleClearStatuses = () => {
        setSearchStatuses([])
        setSearchStatusIds([])
    }

    const handleClearStartDate = () => {
        setSearchStartDate('')

        getStartDates(productionOrders)
    }

    const handleClearDueDate = () => {
        setSearchDueDate('')

        getDueDates(productionOrders)
    }

    const handleChangedStatuses = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })


        if (value[value.length - 1] === "all") {
            setSearchStatuses(searchStatuses.length === statuses.length ? [] : statuses)
            setSearchStatusIds(searchStatuses.length === statuses.length ? [] : statuses.map(i => i.id))
            return;
        }

        setSearchStatuses(duplicateRemoved)
        setSearchStatusIds(ids)
    }

    const handleDeleteSearchStatus = (e, id) => {
        const shouldDelete = searchStatuses.find((x) => x.id === id)
        let statusIds = []
        if (shouldDelete) {
            const filtered = searchStatuses.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                filtered.forEach((status) => {
                    if (status.hasOwnProperty('id')) {
                        searchStatusIds.push(status.id)
                    }
                })
                statusIds = filtered.map((filter) => filter.id)
                setSearchStatuses(filtered)
                setSearchStatusIds(statusIds)
            } else {
                setSearchStatuses([])
                setSearchStatusIds([])
                setOpenStatuses(false)
            }
        }
    }

    const columns = [
        {
            field: 'no',
            headerName: t('production_order_no'),
            flex: 0.7,
            minWidth: 200,
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 0.7,
            minWidth: 200
        },
        {
            field: 'start_date',
            headerName: t('start_date'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'due_date',
            headerName: t('due_date'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'status',
            headerName: t('status'),
            valueGetter: (value) => {
                return value?.value.name ?? '' ?? ''
            },
            flex: 1,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ProductionOrderActions params={params} setIsLoading={setIsLoading} config={config} getProductionOrders={getProductionOrders} user={user} />,
            minWidth: 200
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 pt-0 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='border-b pb-5'>
                                <div className='flex justify-between items-end w-full pt-5 px-5 gap-5'>
                                    <div className='w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input
                                                type="text"
                                                placeholder={t('search_by_production_order_no')}
                                                value={searchNo}
                                                className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place'
                                                onChange={(e) => setSearchNo(e.target.value)}
                                            />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="status-search-label-id">{t('search_by_status')}</InputLabel>
                                            <Select
                                                multiple
                                                value={searchStatuses}
                                                open={openStatuses}
                                                onOpen={() => setOpenStatuses(true)}
                                                onClose={() => setOpenStatuses(false)}
                                                onChange={handleChangedStatuses}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((x) => (
                                                            <Chip
                                                                key={`status_option-${x.id}`}
                                                                label={x.name}
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onDelete={(e) => handleDeleteSearchStatus(e, x.id)}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                                sx={{
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important'
                                                    },
                                                    background: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": {
                                                        display: (searchStatuses.length > 0) ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={
                                                    searchStatuses ? (
                                                        <IconButton sx={{ visibility: (searchStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatuses}><ClearIcon /></IconButton>
                                                    ) : false
                                                }
                                            >
                                                {
                                                    statuses?.length > 0 ?
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox checked={hasSelectedAllStatuses} indeterminate={searchStatuses.length > 0 && searchStatuses.length < statuses.length} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('select_all')} />
                                                        </MenuItem>
                                                        :
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                                }
                                                {
                                                    statuses.map((s) =>
                                                        <MenuItem value={s} key={`status-${s.id}`}>
                                                            <Checkbox checked={searchStatuses?.findIndex((i) => i.id === s.id) >= 0} />
                                                            <ListItemText primary={s.name} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className='flex justify-between items-end w-full pt-5 px-5 gap-5'>
                                    <div className='w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="status-start-date-label-id">{t('search_by_start_date')}</InputLabel>
                                            <Select
                                                value={searchStartDate}
                                                onChange={e => setSearchStartDate(e.target.value)}
                                                label="Search Past Or Present Date"
                                                sx={{
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important'
                                                    },
                                                    background: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": {
                                                        display: searchStartDate ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={
                                                    searchStartDate ? (
                                                        <IconButton sx={{ visibility: searchStartDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearStartDate}><ClearIcon /></IconButton>
                                                    ) : false
                                                }
                                            >
                                                {
                                                    startDates.length < 1 ?
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                        :
                                                        startDates.map((date, index) => (
                                                            <MenuItem key={`startdate-${date}-${index}`} value={date}>{date}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="status-due-date-label-id">{t('search_by_due_date')}</InputLabel>
                                            <Select
                                                value={searchDueDate}
                                                onChange={e => setSearchDueDate(e.target.value)}
                                                label="Search Past Or Present Date"
                                                sx={{
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important'
                                                    },
                                                    background: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": {
                                                        display: searchDueDate ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={
                                                    searchDueDate ? (
                                                        <IconButton sx={{ visibility: searchDueDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDueDate}><ClearIcon /></IconButton>
                                                    ) : false
                                                }
                                            >
                                                {
                                                    dueDates.length < 1 ?
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                        :
                                                        dueDates.map((date, index) => (
                                                            <MenuItem key={`duedate-${date}-${index}`} value={date}>{date}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <ServerTable columns={columns} items={productionOrders} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='production_orders' />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ProductionOrders

const ProductionOrderActions = (params) => {

    const userHasPermissions = (targets = []) => {
        return params.user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const { t } = useTranslation()
    const [openUpdate, setOpenUpdate] = useState(false)

    const handleOpenUpdate = () => {
        setOpenUpdate(!openUpdate)
        localStorage.setItem('prevStatusProduction', params.params.row.status?.id ?? params.params.row.status)
    }

    const deleteProductionOrder = async () => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_productionOrder') + params.params.row.no + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios
            .delete(`/api/production-orders/${params.params.row.id}`, params.config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            })
    }

    return (
        <>
            <div className='flex justify-between'>
                {
                    (
                        includes([1, 2], params.params.row.status?.id ?? params.params.row.status)
                    ) ? (
                        <Tooltip disableInteractive title={t('update')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/production-order/${params.params.row.id}`} state={{ route: '/production-orders' }} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleOpenUpdate}><i className={`fa-solid fa-${!userHasPermissions(['production-orders-update']) ? 'eye' : 'pencil'}`}></i></span></Link>
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/production-order/${params.params.row.id}`} state={{ route: '/production-orders' }} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleOpenUpdate}><i className="fa-solid fa-eye"></i></span></Link>
                            </div>
                        </Tooltip>
                    )
                }

                {
                    (
                        includes(['master_admin', 'office_manager'], params.user?.role)
                        || userHasPermissions(['production-orders-update'])
                    ) ? (
                        <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteProductionOrder}><i className="fa-solid fa-trash"></i></span>
                            </div>
                        </Tooltip>
                    ) : null
                }
            </div>
        </>
    )
}
