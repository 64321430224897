import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import Dropdown from '../Dropdown'
import { DropdownButton } from '../DropdownLink'
import i18next, { t } from 'i18next'
import { useAuth } from '../../hooks/auth'
import { useStateContext } from '../../context/ContextProvider'
import { includes } from 'lodash'
import UserSignatureModal from '../driver/UserSignatureModal'

const Nav = ({ user }) => {
    const { logout, logoutImpersonate } = useAuth()
    const { t } = useTranslation()
    const { total, totalPendingProductionOrders } = useStateContext()

    const [hasFailedToDeimpersonate, setHasFailedToDeimpersonate] = useState(false)
    const [flag, setFlag] = useState(i18next.resolvedLanguage)

    const [userHasSignature, setUserHasSignature] = useState(user?.has_signature ?? false)
    const [userSignatureModalOpen, setUserSignatureModalOpen] = useState(false)
    const location = useLocation()

    const [status, setStatus] = useState([])
    const [errors, setErrors] = useState([])


    const logoutUser = () => {
        logout()
    }

    const deimpersonateUser = async () => {
        logoutImpersonate({
            setErrors: setErrors,
            setStatus: setStatus,
            user: user
        })
    }

    const getInitials = () => {
        let names = user?.name?.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    const languageFlag = () => {
        switch (flag) {
            case 'es':
                return (
                    <img className='w-[25px] h-[25px] rounded-full' src="../assets/images/es.png" alt="es" />
                )
            case 'de':
                return (
                    <img className='w-[25px] h-[25px] rounded-full' src="../assets/images/de.png" alt="de" />
                )
            default:
                return (
                    <img className='w-[25px] h-[25px] rounded-full' src="../assets/images/us.png" alt="us" />
                )
        }
    }

    const page = (url) => {
        switch (url) {
            case '/sales-order':
            case '/sales-orders':
            case '/purchase-order':
            case '/purchase-orders':
            case '/broker-transactions':
            case '/sales-order-calendar':
            case '/sales-orders-calendar':
            case '/purchase-order-calendar':
            case '/purchase-orders-calendar':
            case '/broker-transactions-calendar':
                return (<span>{t('daily_board')}</span>)
            case '/production-order-board':
            case '/production-order-board-calendar':
            case '/production-orders':
            case '/production-order':
                return (<span>{t('production_orders')}</span>)
            case '/admin-dashboard':
                return (<span>{t('dashboard')}</span>)
            case '/map':
                return (<span>{t('map')}</span>)
            case '/reports':
                return (<span>{t('reports')}</span>)
            case '/dispatch':
            case '/dispatch-calendar':
                return (<span>{t('dispatch')}</span>)
            case '/shipments':
                return (<span>{t('shipments')}</span>)
            case '/collections':
                return (<span>{t('collections')}</span>)
            case '/admin-broker-transactions':
                return (<span>{t('broker_transactions')}</span>)
            case '/customer-requests':
                return (<span>{t('customer_requests')}</span>)
            case '/companies':
                return (<span>{t('companies')}</span>)
            case '/sites':
                return (<span>{t('sites')}</span>)
            case '/items':
                return (<span>{t('items')}</span>)
            case '/grade-codes':
                return (<span>{t('grade_codes')}</span>)
            case '/units-of-measure':
                return (<span>{t('units_of_measure')}</span>)
            case '/import':
                return (<span>{t('import')}</span>)
            case '/users':
                return (<span>{t('users')}</span>)
            case '/customers':
                return (<span>{t('customers')}</span>)
            case '/vendors':
                return (<span>{t('vendors')}</span>)
            case '/production-employees':
                return (<span>{t('production_employees')}</span>)
            case '/contacts':
                return (<span>{t('contacts')}</span>)
            case '/production-types':
                return (<span>{t('production_types')}</span>)
            case '/production-items':
                return (<span>{t('production_items')}</span>)
            case '/inventory-sheets':
                return (<span>{t('counts_details')}</span>)
            case '/enter-production-data':
                return (<span>{t('production')}</span>)
            case '/available-loads':
                return (<span>{t('available_loads')}</span>)
            default:
                const isExternalDriverRoute = ['/3pl', '/broker-sales'].some(el => {
                    return url.includes(el)
                })

                return isExternalDriverRoute ? (<span>{t('available_loads')}</span>) : (<span>{t('unknown_page')}</span>)
        }
    }

    const DropdownSignatureButton = () => {
        if(includes(["driver", "tpl_driver", 'service_provider'], user?.role)) {
            return (
                <DropdownButton onClick={() => setUserSignatureModalOpen(true)}><i className="fa-solid fa-pen"></i> {userHasSignature ? t('view_your_signature') : t('add_your_signature')}</DropdownButton>
            )
        }

        return null
    }

    const DropdownLogoutButton = () => {
        if (localStorage.getItem('impersonate') === null) {
            return (
                <DropdownButton onClick={() => logoutUser()}>
                    <i className="fa-solid fa-right-from-bracket"></i> {t('logout')}
                </DropdownButton>
            )
        } else if (hasFailedToDeimpersonate) {
            return (
                <DropdownButton onClick={() => logoutUser()}>
                    <i className="fa-solid fa-triangle-exclamation mr-3" style={{ color: "#d5da0c" }}></i> {t('force_logout')}
                </DropdownButton>
            )
        } else {
            return (
                <DropdownButton onClick={() => deimpersonateUser(user.id)} >
                    {/* <i className="fa-solid fa-user-slash"></i> */} {t('leave_impersonation')}
                </DropdownButton>
            )
        }
    }

    return (
        <div className='flex items-center justify-between p-6'>
            <div>
                <p className='font-[600] text-[#535353] text-[22px]'>{page(window.location.pathname)}</p>
            </div>
            <div className='flex items-center gap-4'>
                {includes(['master_admin', 'office_manager'], user?.role) ? (
                    <Link to='/customer-requests' >
                        <div className='relative text-[20px] text-[#435971] w-[40px] h-[40px] flex items-center justify-center'>
                            <i className="fa-regular fa-bell"></i>
                            <div className='absolute top-0 right-0 rounded-full w-[20px] h-[20px] bg-red-600 text-white flex justify-center items-center text-[13px]'>{total}</div>
                        </div>
                    </Link>
                ) : (includes(['production_supervisor'], user?.role) && location?.pathname !== '/production-order') ? (
                    <Link to='/production-order?get=pending' >
                        <div className='relative text-[20px] text-[#435971] w-[40px] h-[40px] flex items-center justify-center'>
                            <i className="fa-regular fa-bell"></i>
                            <div className='absolute top-0 right-0 rounded-full w-[20px] h-[20px] bg-red-600 text-white flex justify-center items-center text-[13px]'>{totalPendingProductionOrders}</div>
                        </div>
                    </Link>
                ) : null}
                <Dropdown align="right" width="48"
                    trigger={
                        <button className="flex items-center">
                            {languageFlag()}
                        </button>
                    }>
                    {/* Authentication */}
                    <DropdownButton onClick={() => { i18next.changeLanguage('en'); setFlag('en') }}>
                        <div className="flex gap-2 justify-start items-center">
                            <img src="../assets/images/flags/us.png" alt='us' />
                            {t('english')}
                        </div>
                    </DropdownButton>
                    <DropdownButton onClick={() => { i18next.changeLanguage('es'); setFlag('es') }}>
                        <div className="flex gap-2 justify-start items-center">
                            <img src="../assets/images/flags/es.png" alt='es' />
                            {t('spanish')}
                        </div>
                    </DropdownButton>
                    {/* <DropdownButton onClick={() => { i18next.changeLanguage('de'); setFlag('de') }}>
                        <div className="flex gap-2 justify-start items-center">
                            <img src="../assets/images/flags/de.png" alt='de' />
                            {t('germany')}
                        </div>
                    </DropdownButton> */}
                </Dropdown>

                <Dropdown align="right" width="48" className='hidden lg:block'
                    trigger={
                        <div className='cursor-pointer w-[40px] h-[40px] rounded-full bg-white text-[#A2A2A4] text-[13px] flex justify-center items-center relative'>
                            {getInitials()}
                            <div className='w-[10px] h-[10px] bg-green-500 rounded-full absolute bottom-0 right-0'>
                            </div>
                        </div>
                    }>
                    {/* Authentication */}
                    {DropdownSignatureButton()}
                    {DropdownLogoutButton()}
                </Dropdown>
            </div>

            <UserSignatureModal open={userSignatureModalOpen} setOpen={setUserSignatureModalOpen} userId={user?.id} userHasSignature={userHasSignature} setUserHasSignature={setUserHasSignature} />
        </div>
    )
}

export default Nav
