import React, {useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Box, Button, FormControl, MenuItem, Select, Tab, TextField, Tooltip, Stack} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import {isNil, toNumber, includes, isEmpty, has} from 'lodash'
import update from 'immutability-helper'

import { useAuth } from '../../../hooks/auth'
import { useStateContext } from '../../../context/ContextProvider'
import axios from '../../../lib/axios'
import TableShared from '../../../components/TableShared'
import Loading from '../../../components/Loading'
import BrokerSalesOrderButtons from '../../../components/order_buttons/BrokerSalesOrderButtons'
import CommentHistoryModal from '../../../components/CommentHistoryModal'
import CommentHistoryButton from '../../../components/CommentHistoryButton'
import { handleAxiosError, isValid } from '../../../helpers/helper'

const UpdateBrokerSalesOrder = () => {

    const { t } = useTranslation()
    const { company_id, config, setCompany_id, setChoosesite, configFile, currencySymbol, pusher, features } = useStateContext()
    const params = useParams()
    const { user } = useAuth({ middleware: 'guest' })
    const navigate = useNavigate()
    const location = useLocation()

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    /* state */
    const [disabled, setDisabled] = useState(true)
    const [disabledFields, setDisabledFields] = useState(true)
    const [blocked, setBlocked] = useState(false)

    const [salesOrder, setSalesOrder] = useState({})
    const [purchaseOrder, setPurchaseOrder] = useState({})
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [shipToLocations, setShipToLocations] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [value, setValue] = useState("1")
    const [prevStatus, setPrevStatus] = useState('')
    const [driverDocs, setDriverDocs] = useState([])
    const [hasDocs, setHasDocs] = useState(false)
    const [uploadedBillOfLadingDoc, setUploadedBillOfLadingDoc] = useState({})
    const [logisticsTypeKey, setLogisticsTypeKey] = useState('')
    const [deliveryTime, setDeliveryTime] = useState('')

    const [openCommentHistory, setOpenCommentHistory] = useState(false)

    /* form */
    const [customerId, setCustomerId] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [shipToCode, setShipToCode] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs())
    const [shippedDate, setShippedDate] = useState(dayjs())
    const [customerPoNo, setCustomerPoNo] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [status, setStatus] = useState('')
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])
    const [file, setFile] = useState('')
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs())
    const [pickupAppointmentTime, setPickupAppointmentTime] = useState(dayjs())
    const [rejectedReason, setRejectedReason] = useState('')
    const [deliveryTicketNo, setDeliveryTicketNo] = useState('')

    useEffect(() => {
        const channelBrokerTransactionCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        const channelBrokerTransactionDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)

        channelBrokerTransactionCreated.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getBrokerTransaction(data.id, true)
            }
        })
        channelBrokerTransactionDeleted.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/broker-transactions', {
                    replace: true
                })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if(isValid(customerId)) {
            const channelShipLocationCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            const channelShipLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            const channelShipLocationDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)

            channelShipLocationCreated.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
                if(Number(data?.customer_id) == Number(customerId)) {
                    getShipToLocation(data?.id)
                }
            })
            channelShipLocationUpdated.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(data?.customer_id) == Number(customerId)) {
                    getShipToLocations(customerId)
                }
            })
            channelShipLocationDeleted.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if(Number(data?.customer_id) == Number(customerId)) {
                    setShipToLocations((prev) => {
                        const item = prev.find((i) => i.id === data.id)
                        const exItem = prev.filter((i) => i.id !== item.id)
                        return exItem
                    })
                }
            })
        }

        return (() => {
            if(isValid(customerId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            }
        })
    }, [customerId])

    useEffect(() => {
        if(!isEmpty(salesOrder) && has(salesOrder, 'id')) {
            const channelSalesOrderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            const channelSalesOrderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            const channelOrderDocumentUploaded = pusher.subscribe(`${localStorage.getItem('client_id')}-sales-${salesOrder.id}-document-event`)
            const channelCommentCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-comment-created-id-${salesOrder.id}-type-sales`)

            channelSalesOrderUpdated.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(salesOrder.id)) {
                    getBrokerTransaction(params.id, true)
                }
            })
            channelSalesOrderDeleted.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(salesOrder.id)) {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/sales-orders', {
                        replace: true
                    })
                }
            })

            channelOrderDocumentUploaded.bind(`${localStorage.getItem('client_id')}-sales-${salesOrder.id}-document-event`, data => {
                if(data.is_deleted) {
                    setOrderDocuments((prev) => {
                        const deletedDoc = prev.find((i) => i.id === data.id)
                        const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                        return docsUpdated
                    })
                    setDriverDocs((prev) => {
                        const deletedDoc = prev.find((i) => i.id === data.id)
                        const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                        return docsUpdated
                    })
                    setUploadedBillOfLadingDoc((prev) => {
                        return prev.id === data.id ? {} : prev
                    })
                } else {
                    getDocs()
                    getDriverDocs()
                    getUploadedBillOfLadingDoc()
                }
            })

            channelCommentCreated.bind(`${localStorage.getItem('client_id')}-comment-created-event`, data => {
                getComments()
            })
        }

        return (() => {
            if(!isEmpty(salesOrder) && has(salesOrder, 'id')) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-sales-${salesOrder.id}-document-event`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-comment-created-id-${salesOrder.id}-type-sales`)
            }
        })
    }, [salesOrder])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* documents */
    const [orderDocuments, setOrderDocuments] = useState([])

    useEffect(() => {
        getBrokerTransaction(params.id)
    }, [params.id])

    const getBrokerTransaction = async (id, dueToPusherEvent = false) => {
        setIsLoading(true)

        await axios
            .get(`/api/broker-transactions/${id}?with=salesOrder,purchaseOrder,logisticsType`, config)
            .then((res) => {
                const data = res.data

                if(includes(['released', 'assigned_to_driver', 'in_transit', 'shipped'], data.bt_status_slug)) {
                    setSalesOrder(data.sales_order)
                    setPurchaseOrder(data.purchase_order)

                    setCustomerId(data?.customer.id)
                    setCustomerName(data?.customer.name)
                    setLogisticsTypeKey(data?.logistics_type.key)
                    setDeliveryTime(data?.delivery_time)
                    setRequestedDeliveryDate(dayjs(data?.requested_delivery_date))
                    setRequestedDeliveryTime(dayjs(data?.requested_delivery_time))
                    setPickupAppointmentTime(dayjs(data?.pickup_appointment_time))

                    if (!dueToPusherEvent) {
                        setComments('')
                    }
                } else {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if(!isEmpty(salesOrder)) {
            setStatus(salesOrder?.so_status_id)
            setPrevStatus(salesOrder?.prev_status_id)

            setCustomerPoNo(salesOrder?.customer_po_no)

            setDropTrailerNo(salesOrder?.drop_trailer_no)
            setPickupTrailerNo(salesOrder?.pickup_trailer_no)
            setShippedDate(dayjs(salesOrder?.shipment_date))
            setRejectedReason(salesOrder?.rejected_data.rejected_reason)
            setDeliveryTicketNo(salesOrder?.delivery_ticket_no)

            setCommentHistory(salesOrder.comment_history)

            getDocs()
            getDriverDocs()
            getUploadedBillOfLadingDoc()
        }
    }, [salesOrder])

    useEffect(() => {
        if(!isEmpty(purchaseOrder)) {
            setPurchaseOrderItems(purchaseOrder?.items)
        }
    }, [purchaseOrder])

    useEffect(() => {
        loadShipToLocation()
    }, [shipToLocations])

    useEffect(() => {
        if (isValid(customerId)) {
            getShipToLocations(customerId)
        }
    }, [customerId])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [purchaseOrderItems, params])

    useEffect(() => {
        const items = purchaseOrderItems.filter((item) => item.qty_ordered !== "")

        if (items) {
            const sum = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            if (sum > 0) {
                setBlocked(false)
            }
            else {
                setBlocked(true)
            }
        }

    }, [purchaseOrderItems])

    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])

    /* Handlers */

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleTotalPrice = () => {
        const sum = purchaseOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = purchaseOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }


    const handleCustomerPoNo = (e) => {
        const limit = 50
        setCustomerPoNo(e.target.value.slice(0, limit))
    }

    const loadShipToLocation = () => {
        const shipToLocation = shipToLocations?.find(target => target.id === shipToCode)
        setAddress(shipToLocation?.address)
        setZip(shipToLocation?.zip)
        setCity(shipToLocation?.city)
        setState(shipToLocation?.state)
        setDeliveryInstructions(shipToLocation?.delivery_instructions)
    }

    /* API */

    const getDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${salesOrder.id}&order_type=sales&document_type=order_document&uploaded_by=${user?.id}`, config)
            .then(res => {
                const files = res.data
                setOrderDocuments(files)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDriverDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${salesOrder.id}&order_type=sales&document_type=drivers_document`, config)
            .then(res => {
                const files = res.data
                setDriverDocs(files)
                if (files.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedBillOfLadingDoc = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${salesOrder.id}&order_type=sales&document_type=proof_of_delivery`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedBillOfLadingDoc({})
                } else {
                    setUploadedBillOfLadingDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDocs = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_docs') + name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        setIsLoading(true)
        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);

        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('order_id', salesOrder.id)
        formData.append('order_type', 'sales')
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                inputRef.current.value = ""
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const leaveComment = async () => {
        setIsLoading(true)

        const formData = new FormData()

        formData.append('order_id', salesOrder.id)
        formData.append('order_type', 'sales')
        formData.append('content', comments)

        await axios
            .post(`/api/comments`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const confirmBrokerTransaction = async () => {
        setIsLoading(true)

        await axios
            .put(`/api/broker-transactions/${params.id}/confirm`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success?.message
                }).then((result) => {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getComments = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/comments?order_id=${salesOrder.id}&order_type=sales`)
            .then(res => {
                const data = res.data
                setCommentHistory(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses/${id}`, config)
            .then((res) => {
                const shipToLocation = res.data
                setShipToLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, shipToLocation]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: shipToLocation } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const shipToLocations = res.data
                setShipToLocations(shipToLocations)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
        setShipToCode(salesOrder.ship_address_id)
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedCell params={params} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceiveCell params={params} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedCell params={params} />
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <div className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span>
                            <p>{t('view_so')} - {salesOrder.so_number}</p>
                        </div>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={() => navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        {/* Status */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('status')}</p>
                            <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                {salesOrder.so_status_name}
                            </div>
                        </div>
                        {/* Rejection Reasoning */}
                        {salesOrder?.rejected ? (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('reason_for_rejection')}</p>
                                <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{t(`${rejectedReason}`)}</div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Customer */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled className='w-full' name="drop_trailer_no" value={customerName} />
                            </div>
                        </div>

                        {/* Ship To Location */}

                        {logisticsTypeKey != 'pick_up' ? (
                            <>
                                <div className='w-full pb-4'>
                                    <Stack
                                        direction='row'
                                        gap={1}
                                        alignItems='baseline'
                                        justifyContent='space-between'
                                    >
                                        <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                        {(shipToCode && isValid(deliveryInstructions)) ? (
                                            <Tooltip
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {deliveryInstructions}
                                                    </div>
                                                }
                                            >
                                                <Stack
                                                    direction='row'
                                                    gap={1}
                                                    alignItems='center'
                                                    className='text-[#718096]'
                                                >
                                                    <MenuBookIcon fontSize='18px'/>
                                                    <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                                </Stack>
                                            </Tooltip>
                                        ) : null}
                                    </Stack>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                value={shipToCode}
                                                disabled
                                            >
                                                {shipToLocations?.map((item, index) => <MenuItem key={`stlid-${item.id}_${index}`} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {/* Address */}
                                {
                                    shipToCode ?
                                        <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                            <div className='w-full'>{address}</div>
                                            {shipToCode ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                        </div>
                                        :
                                        null
                                }
                            </>
                        ) : (
                            <div className='w-full p-4 address mb-4'>
                                <div className='w-full text-[#00000061]'>{t('pick_up')}</div>
                            </div>
                        )}

                        {/* Drop Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                            </div>
                        </div>

                        {/* Pickup Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                            </div>
                        </div>

                        {/* Customer PO No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('customer_po_no')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="customer_po_no" value={customerPoNo} onChange={(e) => { handleCustomerPoNo(e) }} />
                                <p className='text-xs italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                            </div>
                        </div>

                        {/* Delivery Ticket No. */}
                        {
                            includes(['oliverswoodworking'], localStorage.getItem('client_id')) ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('delivery_ticket_no')}</p>
                                    <div className='w-full'>
                                        <TextField
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            type="text" disabled={disabledFields} className='w-full' name="delivery_ticket_no" value={deliveryTicketNo} onChange={(e) => { setDeliveryTicketNo(e.target.value) }} />
                                    </div>
                                </div>
                            ) : null
                        }

                        <div className='lg:flex gap-3 w-full'>
                            {/* Requested Delivery Date */}
                            <div className={`w-full ${(deliveryTime == 'appointment') ? 'lg:w-1/2' : ''} pb-4`}>
                                <p className="text-[14px] text-[#718096]">{t('requested_delivery_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker disabled={disabledFields}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" value={requestedDeliveryDate} onChange={(newValue) => setRequestedDeliveryDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            {(includes(['delivery', 'tpl'], logisticsTypeKey) && deliveryTime == 'appointment') && (
                                <div className={`w-full lg:w-1/2 pb-4`}>
                                    {logisticsTypeKey == 'delivery' && (
                                        <>
                                            <p className="text-[14px] text-[#718096]">{t('delivery_appointment_time')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker disabled={disabledFields}
                                                            sx={{
                                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                            }}
                                                            value={requestedDeliveryTime} onChange={(newValue) => setRequestedDeliveryTime(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </>
                                    )}

                                    {logisticsTypeKey == 'pick_up' && (
                                        <>
                                            <p className="text-[14px] text-[#718096]">{t('pickup_appointment_time')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker disabled={disabledFields}
                                                            sx={{
                                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                            }}
                                                            value={pickupAppointmentTime} onChange={(newValue) => setPickupAppointmentTime(newValue)} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Shipment Date */}
                        {
                            <div className={`w-full pb-4`}>
                                <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker disabled={disabledFields}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" value={shippedDate} onChange={(newValue) => setShippedDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                            <div className='pb-11 pt-1 pl-5'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                            </div>
                            <div className='border-b border-t mt-4'>
                                <TableShared columns={columns} items={purchaseOrderItems} disableColumnMenu={true} />
                            </div>
                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                        <p>{t('totalPrice')}</p>
                                        <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{totalQty}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block gap-3 lg:flex'>
                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2 h-fit'>
                                <div className='pt-1 p-5 flex justify-between items-start'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={commentHistory?.length ?? ''}
                                        backgroundColor='#336195'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <div className='w-full p-5 pt-0'>
                                    <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        value={comments}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                    />
                                </div>
                                <div className='pt-1 p-5 flex justify-end items-start'>
                                    <button type="button" onClick={leaveComment} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#336195] hover:bg-[#284d77] disabled:bg-[#B8B7BC]'>
                                        {t('leave_comment')}
                                    </button>
                                </div>
                            </div>

                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                </div>
                                <div className='w-full'>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="document tab list" variant='scrollable'>
                                                <Tab sx={{ textTransform: 'none' }} value="1" label={t('order_documents')} />
                                                <Tab sx={{ textTransform: 'none' }} value="2" label={t('bill_of_lading')} />
                                            </TabList>
                                        </Box>
                                        {/* ORDER DOCUMENTS */}
                                        <TabPanel value="1">
                                            <div className='w-full text-center'>
                                                <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                    <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                </Button>
                                                <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'order_document')} />
                                            </div>
                                            <>
                                                {
                                                    orderDocuments?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                        || item.uploaded_by === user?.id
                                                                    ) ? (
                                                                        <button className={`text-neutral-500 ${(disabledFields && !includes(['vendor_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {hasDocs ? (
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_documents')}</div>
                                                ) : (
                                                    ''
                                                )}
                                                {
                                                    driverDocs?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                        || item.uploaded_by === user?.id
                                                                    ) ? (
                                                                        <button className={`text-neutral-500 ${(disabledFields && !includes(['vendor_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </TabPanel>

                                        {/* PROOFS OF DELIVERY */}
                                        <TabPanel value="2">

                                            {/* Auto-generated Bills of Lading */}

                                            <div className='pt-5 flex justify-between'>
                                                <p className='text-blue-900 underline'><a
                                                    href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=view&party=supplier&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                    target="_blank"><span className="text-[#336195]"
                                                        style={{ cursor: 'pointer' }}>{t('bill_of_lading')}</span></a>
                                                </p>
                                                <div className='flex items-center gap-3'>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=view&party=supplier&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                className="fa-solid fa-eye"></i></Tooltip></button>
                                                    </a>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=download&party=supplier&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                className="fa-solid fa-download"></i></Tooltip></button>
                                                    </a>
                                                </div>
                                            </div>

                                            {/* {
                                                salesOrder?.type == '3PL' ?
                                                    <div className='pt-5 flex justify-between'>
                                                        <p className='text-blue-900 underline'><a
                                                            href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                            target="_blank"><span className="text-[#336195]"
                                                                style={{ cursor: 'pointer' }}>3PL {t('bill_of_lading')}</span></a>
                                                        </p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-eye"></i></Tooltip></button>
                                                            </a>
                                                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-download"></i></Tooltip></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            } */}

                                            {/* Manually-uploaded Bills of Lading */}

                                            {Object.keys(uploadedBillOfLadingDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('uploaded_x', { x: t('bill_of_lading') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedBillOfLadingDoc.document_url} target="_blank" download>{uploadedBillOfLadingDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedBillOfLadingDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                    || uploadedBillOfLadingDoc.uploaded_by === user?.id
                                                                ) ? (
                                                                    <button className={`text-neutral-500 ${(disabledFields && !includes(['vendor_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(uploadedBillOfLadingDoc.id, uploadedBillOfLadingDoc.document_name)}><Tooltip disableInteractive title={t('delete_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='pt-5 w-full text-center'>
                                                    <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('BoL') })}</p> </div>
                                                    </Button>
                                                    <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'proof_of_delivery')} />
                                                </div>
                                            )}
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-3'>
                            {
                                (
                                    !salesOrder?.rejected
                                    && salesOrder?.type == 'BROKER'
                                ) ? (
                                    <BrokerSalesOrderButtons status={status} confirm={confirmBrokerTransaction} disabled={disabledFields} blocked={blocked} user={user} />
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </Box>
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default UpdateBrokerSalesOrder

const QtyOrderedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            disabled
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty"
            value={qty}
        />
    )

}

const QtyToReceiveCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_receive])

    return (
        <TextField
            type="text"
            disabled
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-receive"
            value={qty}
        />
    )

}

const QtyReceivedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_received])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received"
            value={qty}
            disabled
        />
    )
}

const VendorCountCell = ({ params }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.vendor_count])

    return (
        <TextField
            type="text"
            variant='standard'
            disabled
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count"
            value={qty}
        />
    )
}

const UnitPriceCell = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {

    const [total, setTotal] = useState(params.params.value)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}
